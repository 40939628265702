import React from 'react'
import { HTMLTable } from '@blueprintjs/core'
import { Link } from '@reach/router'
import { isString } from 'formik';
import { Icon } from '../../elements'
import moment from 'moment';


const dueDateStyle = (item) => {
  let currentDate = new Date();
  currentDate.setDate(currentDate.getDate() + 30);
  const due = new Date(item.dueDate);
  const statusPending = !item.status || item.status.id < 9
  if (due < currentDate && statusPending) {
    return {
      "color": "red"
    }
  } else {
    return {
      "color": "black"
    }
  }
}

const getSortIcon = (column, sortValue, sortOrder) => {
  if (column === sortValue) {
      return sortOrder === 'asc' ? <Icon name='chevron-down'/> : <Icon name='chevron-up'/>
  }
}

export default ({ updateSortOrder, sortValue, sortOrder, items = [] }) => {
  const thisUpdateSortOrder = updateSortOrder;
  return (
    <>
      <HTMLTable striped>
        <thead>
          <tr>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('hospital')}>Hospital {getSortIcon('hospital', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('account')}>Account # {getSortIcon('account', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('status')}>Status {getSortIcon('status', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('dueDate')}>Due Date {getSortIcon('dueDate', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('assignedTo')}>Assigned To {getSortIcon('assignedTo', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('admitDOSDate')}>Admit Date {getSortIcon('admitDOSDate', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('dischargeDate')}>Discharge Date {getSortIcon('dischargeDate', sortValue, sortOrder)}</th>
            <th className="sortHeader" onClick={() => thisUpdateSortOrder('lastName')}>Patient Name {getSortIcon('lastName', sortValue, sortOrder)}</th>
            <th className="plainHeader"></th>
            <th className="plainHeader"></th>
          </tr>
        </thead>

        <tbody>
          {items.map(item => {
              const admitDate = item.admitDOSDate && moment(item.admitDOSDate).format("MM-DD-YYYY")
              const hospital = item.hospital ? item.hospital.name : null
              const link = `${item.lastName}, ${item.firstName} - ${admitDate} - ${hospital}`            
            return (
            <tr key={item.claim}>
              <td>{item.hospital ? item.hospital.name : ""}</td>
              <td>{item.account}</td>
              <td>{item.status ? item.status.name : ""}</td>
              <td style={dueDateStyle(item)}>{item.dueDate}</td>
              <td>{item.assignedTo ? item.assignedTo.name : "Unassigned"}</td>
              <td>{item.admitDOSDate}</td>
              <td>{item.dischargeDate}</td>
              <td>{item.lastName}, {item.firstName}</td>
              <td><Link to={'claim/edit/' + item.claim}
                onClick={() => isString(item.claim)}>Edit</Link></td>
            <td><a target="_blank" rel="noopener noreferrer" href={"https://centralizedappealscom.sharepoint.com/:f:/r/Shared%20Documents/" + link}>Directory</a></td>
            </tr>
          )})}
        </tbody>
      </HTMLTable>
    </>
  )
}