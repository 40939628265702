import React from 'react'
import { Formik } from 'formik'
import ClaimForm from '../ClaimForm'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ClaimSchema_ } from '@ca/validation'
import { navigate } from '@reach/router'
import moment from 'moment'

const FRAGMENT = gql`
  fragment AddFragment on ClaimDetails {
    claim
    hospital {
        id
        name
      }
      status {
        name
        id
      }
      assignedTo {
        name
        id
        email
      }
      finClass {
        name
        id
      }
      denialType {
        name
        id
      }
      careLevel {
        name
        id
      }
      accountType {
        name
        id
      }
      dueDate
      account
      referralDate
      fadLetterDate
      iadLetterDate
      medicalRecord
      firstName
      lastName
      phone
      payer
      admitDoctor
      admitDOSDate
      dischargeDate
      assignedDate
      unitsDenied
      unitsWon
      externalFile
      externalReview
      concurrentFee
      concurrentDate
      billedCharges
      paidWriterDate
      cannotBeAppealed
      expectedRevenue
      revenue
      address
      paidCADate
      invoicedDate
      appealSubmitDate
      invoiceNumber
      externalAppFee
      externalAppPaidDate
      externalAppDate
      closeDate
      principalDX
      notes {
        id
        date
        notes
      }
  }`

const QUERY = gql`
  query {
    appeal_statuses {
      name
      id
    }
    disposition_statuses {
      name
      id
    }
    hospitals {
      id
      name
    }
    assignedTos {
      name
      id
      email
    }

    accountTypes {
      id
      name
    }

    noAppealTypes {
          id
          name
      }

    appealDenials {
      id
      name
    }

    finClasses {
      id
      name
    }
    denialTypes {
      id
      name
    }
    careLevels {
      id
      name
    }
  }
`

const MUTATION = gql`
  mutation CreateClaim($claim_details: ClaimInput!) {
    createClaim(claim_details: $claim_details) {
      ...AddFragment
    }
  }
  ${FRAGMENT}`

export default ({ claim }) => {
  const [createClaim] = useMutation(MUTATION)
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only'
  })
  if (loading)
    return (<div>Loading...</div>)

  const onSubmit = async (props) => {
      debugger;
      const model = ClaimSchema_.cast(props, { stripUnknown: true })
      const email = model.assignedTo && model.assignedTo.id ? data.assignedTos.find(d => d.id === model.assignedTo.id).email : null
      const hospital = model.hospital && data.hospitals.find(h => h.id === model.hospital.id).name
      const admitDate = model.admitDOSDate && moment(model.admitDOSDate).format("MM-DD-YYYY")
      fetch('https://prod-74.westus.logic.azure.com:443/workflows/6ed585817ff046608c9df974358c8570/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=dnbEoQWT7nUohq1pSV5C4I1xL2Jz6NbxWuDbJVKbkT0', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          claim: model.claim,
          assignedTo: email,
          folderName: `${model.lastName}, ${model.firstName} - ${admitDate} - ${hospital}`
        })
      })
      await createClaim({
        variables: {
          claim_details: model
        }
      })
      navigate('/')
    }
    return (
      <>
        <h1>Add Claim</h1>
        <Formik
          initialValues={{ referralDate: moment().format('YYYY-MM-DD') }}
          onSubmit={onSubmit}
          validationSchema={ClaimSchema_}
          render={(props) =>  {
            return <ClaimForm
            errors={props.errors}
            statuses={data && data.appeal_statuses ? data.appeal_statuses : []}
            disposition_statuses={data && data.disposition_statuses ? data.disposition_statuses : []}
            assigned={data && data.assignedTos ? data.assignedTos : []}
            hospitals={data && data.hospitals ? data.hospitals : []}
            accountTypes={data && data.accountTypes ? data.accountTypes : []}
            appealDenials={data && data.appealDenials ? data.appealDenials : []}
            noAppealTypes={data && data.noAppealTypes ? data.noAppealTypes : []}
            finClasses={data && data.finClasses ? data.finClasses : []}
            denialTypes={data && data.denialTypes ? data.denialTypes : []}
            careLevels={data && data.careLevels ? data.careLevels : []}
            {...props} />}}
            /></>
    )
}
