import React from 'react'
import { Formik } from 'formik'
import ClaimForm from '../ClaimForm'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ClaimSchema_ } from '@ca/validation'
import { navigate } from '@reach/router'
import moment from 'moment'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const FRAGMENT = gql`
  fragment EditFragment on ClaimDetails {
    claim
    hospital {
        id
        name
      }
      appealDenial {
        id
        name
      }
      status {
        name
        id
      }
      assignedTo {
        name
        id
        email
      }
      finClass {
        name
        id
      }
      denialType {
        name
        id
      }
      careLevel {
        name
        id
      }
      accountType {
        name
        id
      }
      dueDate
      account
      referralDate
      fadLetterDate
      iadLetterDate
      medicalRecord
      firstName
      lastName
      phone
      payer
      admitDoctor
      admitDOSDate
      dischargeDate
      assignedDate
      unitsDenied
      unitsWon
      externalFile
      externalReview
      concurrentFee
      concurrentDate
      billedCharges
      paidWriterDate
      cannotBeAppealed
      expectedRevenue
      revenue
      address
      paidCADate
      invoicedDate
      appealSubmitDate
      invoiceNumber
      externalAppFee
      externalAppPaidDate
      externalAppDate
      closeDate
      principalDX
      dob
      firstSubmitDate
      secondSubmitDate
      thirdSubmitDate
      firstDecisionDate
      secondDecisionDate
      thirdDecisionDate
      closingBalance
    dispositionDate
    dispositionStatus{
      name
      id
    }
    noAppealType{
      id
    }
      notes {
        id
        date
        notes
      }
  }`

const QUERY = gql`
  ${FRAGMENT}
  query ($id: ID!) {
    claim(id: $id) {
      ...EditFragment
    }
    appeal_statuses {
      name
      id
    }
    disposition_statuses {
      name
      id
    }
    hospitals {
      id
      name
    }
    assignedTos {
    name
    id
    email
  }
  finClasses {
    name
    id
  }
  denialTypes {
    name
    id
  }
  careLevels {
        name
        id
  }
  accountTypes {
    name
    id
  }
  noAppealTypes {
          id
          name
      }
  appealDenials {
    id
    name
  }
  }
`

const EDITMUTATION = gql`
  mutation UpdateClaim($id: ID!, $claim_details: ClaimInput!) {
    updateClaim(id: $id, claim_details: $claim_details) {
      ...EditFragment
    }
  }
  ${FRAGMENT}`

const DELETEMUTATION = gql`
  mutation DeleteClaim($id: ID!) {
    deleteClaim(id: $id)
  }`

export default ({ claim, ...props }) => {
  const [updateClaim] = useMutation(EDITMUTATION)
  const [deleteClaim] = useMutation(DELETEMUTATION)
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only',
    variables: {
      id: claim
    }
  })
  if (loading || !data) {
    return (<div>Loading...</div>)
  } else {
    console.log('data', data)
    const yesDeleteClaim = async (claim) => {
      await deleteClaim({
        variables: {
          id: claim
        }
      })
      navigate('/')
    }
    const onSubmit = async (props) => {
      const model = ClaimSchema_.cast(props, { stripUnknown: true })
      const hospital = model.hospital && data.hospitals.find(h => h.id === model.hospital.id).name
      const admitDate = model.admitDOSDate && moment(model.admitDOSDate).format("MM-DD-YYYY")
      if (model.assignedTo && model.assignedTo.id && (data.claim.assignedTo === null || model.assignedTo.id !== data.claim.assignedTo.id)) {
        const email = model.assignedTo.id ? data.assignedTos.find(d => d.id === model.assignedTo.id).email : null
        fetch('https://prod-22.westus.logic.azure.com:443/workflows/2fef1fb397b94ff7bbe32d1420d4a78b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=T0Lkn7WBpERDlMrcGJzzxPb0VtdctpyXGX4oghVO5JM', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            claim: model.claim,
            assignedTo: email,
            folderName: `${model.lastName}, ${model.firstName} - ${admitDate} - ${hospital}`
          })
        })
      }
      await updateClaim({
        variables: {
          id: claim,
          claim_details: model
        }
      })
      navigate('/')
    }
    const removeClaim = (claim) => {
      confirmAlert({
        title: 'Confirm Deletion',
        message: `Are you sure you want to delete claim ${claim}?`,
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              yesDeleteClaim(claim)
            }
          },
          {
            label: 'No',
            onClick: (e) => e
          }
        ]
      })
      return (
        <div className='container'>
          <button>Confirm dialog</button>
        </div>
      );
    }
    return (
      <>
        <h1>Edit Claim</h1>
        <Formik
          initialValues={data && data.claim ? data.claim : ''}
          onSubmit={onSubmit}
          validationSchema={ClaimSchema_}
          render={(props) => {
            return <ClaimForm
              statuses={data && data.appeal_statuses ? data.appeal_statuses : ''}
              disposition_statuses={data && data.disposition_statuses ? data.disposition_statuses : []}
              assigned={data && data.assignedTos ? data.assignedTos : ''}
              hospitals={data && data.hospitals ? data.hospitals : ''}
              finClasses={data && data.finClasses ? data.finClasses : ''}
              denialTypes={data && data.denialTypes ? data.denialTypes : ''}
              careLevels={data && data.careLevels ? data.careLevels : []}
              accountTypes={data && data.accountTypes ? data.accountTypes : ''}
              appealDenials={data && data.appealDenials ? data.appealDenials : []}
              noAppealTypes={data && data.noAppealTypes ? data.noAppealTypes : []}
              existingClaim={true}
              removeClaim={removeClaim}
              {...props} />
          }}
        /></>
    )
  }


}
