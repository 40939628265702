import React from 'react'
import { HTMLTable } from '@blueprintjs/core'
import { Icon } from '../../elements'

const totalRowStyle = {
    fontWeight: '600',
    border: '1px solid black',
  }

  const currencyCellStyle = {
      textAlign: 'right'
  }

const getSortIcon = (column, sortValue, sortOrder) => {
    if (column === sortValue) {
        return sortOrder === 'asc' ? <Icon name='chevron-down'/> : <Icon name='chevron-up'/>
    }
}

export default ({ updateSortOrder, sortValue, sortOrder, items = [] }) => {
    const exRevTotal = items.length && items.reduce(function (tot, claim) {
        return (tot + Number(claim.expectedRevenue || '0'));
      }, 0);
      const revTotal = items.length && items.reduce(function (tot, claim) {
        return (tot + Number(claim.revenue || '0'));
      }, 0);
    return (
        <>
        <HTMLTable striped>
            <thead>
                <tr>
                    <th className="sortHeader" onClick={() => updateSortOrder('account')}>Account # {getSortIcon('account', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('dueDate')}>Due Date {getSortIcon('dueDate', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('updated')}>Last Updated {getSortIcon('updated', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('status')}>Status {getSortIcon('status', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('expectedRevenue')}>Expected Revenue {getSortIcon('expectedRevenue', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('revenue')}>Total Cash Recovery {getSortIcon('revenue', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('referralDate')}>Referral to CA Date {getSortIcon('referralDate', sortValue, sortOrder)}</th>
                </tr>
            </thead>

            <tbody>
                {items.map(item => (
                    <tr key={item.claim}>
                        <td>{item.account}</td>
                        <td>{item.dueDate}</td>
                        <td>{item.updated}</td>
                        <td>{item.status ? item.status.name : ""}</td>
                        <td style={currencyCellStyle}>$ {item.expectedRevenue ? (Number(item.expectedRevenue)).toFixed(2) : '0.00'}</td>
                        <td style={currencyCellStyle}>$ {item.revenue ? (Number(item.revenue)).toFixed(2) : '0.00'}</td>
                        <td>{item.referralDate}</td>
                    </tr>))}
                    <tr style={totalRowStyle}>
                        <td>Totals:</td><td></td><td></td><td></td>
                        <td style={currencyCellStyle}>$ {exRevTotal.toFixed(2)}</td><td style={currencyCellStyle}>$ {revTotal.toFixed(2)}</td><td></td>
                        </tr>
            </tbody>
        </HTMLTable>
          </>
    )
}