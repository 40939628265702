import React from 'react'
import moment from 'moment'
import { Table } from '../../../elements'

const tableStyle = {
  border: '1px solid black',
  maxWidth: '750px',
  tableLayout: 'fixed'
}
const dollarHeaderStyle = {
  textAlign: 'center',
  border: '1px solid black',
  width: '100px',
}
const headerStyle = {
  textAlign: 'center',
  border: '1px solid black',
  width: '155px'
}

const tinyHeaderStyle = {
  maxWidth: '45px',
  width: '45px',
  border: '1px solid black'
}
const longHeaderStyle = {
  textAlign: 'center',
  border: '1px solid black',
  width: '200px'
}
const dollarStyle = {
  textAlign: 'right'
}
const percentStyle = {
  textAlign: 'center'
}
const subTextStyle = {
  fontSize: 'x-small'
}
const boldStyle = {
  textAlign: 'center',
  fontWeight: '600'
}

const miniTableStyle = {
  maxWidth: '750px',
  marginLeft: '100px',
  border: '1px solid black',
}

const cellStyle = {
  border: '1px solid black',
  textAlign: 'center',
  fontWeight: '600'
}

export default ({ data, claims }) => {
  const placement = (input) => input.length && (input.reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0));
  }, 0));
  const disc = (input) => input.filter(c => c.noAppealType && c.noAppealType.id)
  const discAmount = (input) => disc(input).length && disc(input).reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0));
  }, 0);
  const closed = (input) => input.filter(c => c.status && !c.noAppealType && (c.status.id === 11 || c.status.id === 12))
  const closedAmount = (input) => closed(input).length && closed(input).reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0));
  }, 0);
  const medicare = (input) => closed(input).filter(c => c.finClass && c.finClass.id === 1)
  const medicareAmount = (input) => medicare(input).reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0));
  }, 0);
  const medicaid = (input) => closed(input).filter(c => c.finClass && c.finClass.id === 2)
  const medicaidAmount = (input) => medicaid(input).reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0));
  }, 0);
  const commercial = (input) => closed(input).filter(c => c.finClass && c.finClass.id === 3)
  const commercialAmount = (input) => commercial(input).reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0));
  }, 0);
  const discBadDebt = (input) => input.filter(c => c.status && !c.noAppealType && c.status.id === 10).reduce(function (tot, claim) {
    return (tot + Number(claim.expectedRevenue || 0))
  }, 0);
  const liquidationRate = (input) => (placement(input) - discAmount(input) - balance(input)) ? (100*(closedAmount(input) / (placement(input) - discAmount(input) - balance(input)))).toFixed(2) : '0'
  const balance = (input) => ((placement(input) - discAmount(input)) - closedAmount(input) - discBadDebt(input)).toFixed(2) || 0

  const remBalancePercent = (input) => ((placement(input) - discAmount(input))) > 0 ? (100*(balance(input)/(placement(input) - discAmount(prev11mo)))).toFixed(2) : '0'
  const totals = (input) => (Number(closedAmount(input)) + Number(discBadDebt(input)) + Number(balance(input))).toFixed(2) || '0.00'
  const prev11mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(11, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(11, 'month').endOf('month')))
  const prev10mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(10, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(10, 'month').endOf('month')))
  const prev9mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(9, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(9, 'month').endOf('month')))
  const prev8mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(8, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(8, 'month').endOf('month')))
  const prev7mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(7, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(7, 'month').endOf('month')))
  const prev6mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(6, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(6, 'month').endOf('month')))
  const prev5mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(5, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(5, 'month').endOf('month')))
  const prev4mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(4, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(4, 'month').endOf('month')))
  const prev3mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(3, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(3, 'month').endOf('month')))
  const prev2mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(2, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(2, 'month').endOf('month')))
  const prev1mo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(1, 'month').startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().subtract(1, 'month').endOf('month')))
  const currentMo = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().startOf('month')) && moment(c.referralDate).isSameOrBefore(moment().endOf('month')))
  const prev2ytotal = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(2, 'year').startOf('year')) && moment(c.referralDate).isSameOrBefore(moment().subtract(2, 'year').endOf('year')))
  .reduce(function (tot, claim) {
      return ((tot + Number(claim.revenue)))
    }, 0)
    const prev1ytotal = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().subtract(1, 'year').startOf('year')) && moment(c.referralDate).isSameOrBefore(moment().subtract(1, 'year').endOf('year')))
    .reduce(function (tot, claim) {
        return ((tot + Number(claim.revenue)))
      }, 0)
      const currentytotal = claims.filter(c => c.referralDate && moment(c.referralDate).isSameOrAfter(moment().startOf('year')) && moment(c.referralDate).isSameOrBefore(moment().endOf('year')))
      .reduce(function (tot, claim) {
          return ((tot + Number(claim.revenue)))
        }, 0)
  const totalYears = prev2ytotal + prev1ytotal + currentytotal
  let headers = []
  let subheaders = []
  let i;
  for (i = 11; i > -1; i--) {
    let date = moment().subtract(i, 'month')
    let dateString = date.format("MMM YY")
    let htmlDate = <th colSpan='3' key={dateString} className="grayHeader" style={headerStyle}>{dateString}</th>
    headers.push(htmlDate)
  }
  for (i = 11; i > -1; i--) {
    let subheader = <><td style={tinyHeaderStyle} key={`No${i}`}>No.</td><td key={`$${i}`} colSpan='2' style={dollarHeaderStyle}>$</td></>
    subheaders.push(subheader)
  }
  headers.unshift(<th style={longHeaderStyle}>Rows</th>)
  subheaders.unshift(<th style={headerStyle}></th>)
  return (
    <>
      <Table style={tableStyle}>
        <thead className="grayHeader">
          <tr className="grayHeader" >{headers}</tr>
          <tr className="grayHeader">{subheaders}</tr>
        </thead>
        <tbody>
          <tr>
            <td style={headerStyle}>Placement</td>
            <td>{prev11mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev11mo).toFixed(2) || '0.00'}</td>
            <td>{prev10mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev10mo).toFixed(2) || '0.00'}</td>
            <td>{prev9mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev9mo).toFixed(2) || '0.00'}</td>
            <td>{prev8mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev8mo).toFixed(2) || '0.00'}</td>
            <td>{prev7mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev7mo).toFixed(2) || '0.00'}</td>
            <td>{prev6mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev6mo).toFixed(2) || '0.00'}</td>
            <td>{prev5mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev5mo).toFixed(2) || '0.00'}</td>
            <td>{prev4mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev4mo).toFixed(2) || '0.00'}</td>
            <td>{prev3mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev3mo).toFixed(2) || '0.00'}</td>
            <td>{prev2mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev2mo).toFixed(2) || '0.00'}</td>
            <td>{prev1mo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(prev1mo).toFixed(2) || '0.00'}</td>
            <td>{currentMo.length}</td>
            <td colSpan='2' style={dollarStyle}>$ {placement(currentMo).toFixed(2) || '0.00'}</td>
          </tr><tr>
            <td style={headerStyle}>Disc-Client Request</td>
            <td>{disc(prev11mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev11mo) || '0.00'}</td>
            <td>{disc(prev10mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev10mo) || '0.00'}</td>
            <td>{disc(prev9mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev9mo) || '0.00'}</td>
            <td>{disc(prev8mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev8mo) || '0.00'}</td>
            <td>{disc(prev7mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev7mo) || '0.00'}</td>
            <td>{disc(prev6mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev6mo) || '0.00'}</td>
            <td>{disc(prev5mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev5mo) || '0.00'}</td>
            <td>{disc(prev4mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev4mo) || '0.00'}</td>
            <td>{disc(prev3mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev3mo) || '0.00'}</td>
            <td>{disc(prev2mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev2mo) || '0.00'}</td>
            <td>{disc(prev1mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(prev1mo) || '0.00'}</td>
            <td>{disc(currentMo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {discAmount(currentMo) || '0.00'}</td>
          </tr><tr>
            <td style={headerStyle}>Net Placement (Expected Reimbursement)</td>
            <td>{prev11mo.length - disc(prev11mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev11mo) - discAmount(prev11mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev10mo.length - disc(prev10mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev10mo) - discAmount(prev10mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev9mo.length - disc(prev9mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev9mo) - discAmount(prev9mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev8mo.length - disc(prev8mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev8mo) - discAmount(prev8mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev7mo.length - disc(prev7mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev7mo) - discAmount(prev7mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev6mo.length - disc(prev6mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev6mo) - discAmount(prev6mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev5mo.length - disc(prev5mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev5mo) - discAmount(prev5mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev4mo.length - disc(prev4mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev4mo) - discAmount(prev4mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev3mo.length - disc(prev3mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev3mo) - discAmount(prev3mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev2mo.length - disc(prev2mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev2mo) - discAmount(prev2mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{prev1mo.length - disc(prev1mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(prev1mo) - discAmount(prev1mo)).toFixed(2)).toLocaleString() || '0.00'}</td>
            <td>{currentMo.length - disc(currentMo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {((placement(currentMo) - discAmount(currentMo)).toFixed(2)).toLocaleString() || '0.00'}</td>
          </tr><tr>
            <td style={headerStyle}>Resolved Placement</td>
            <td>{closed(prev11mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev11mo) || '0.00'}</td>
            <td>{closed(prev10mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev10mo) || '0.00'}</td>
            <td>{closed(prev9mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev9mo) || '0.00'}</td>
            <td>{closed(prev8mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev8mo) || '0.00'}</td>
            <td>{closed(prev7mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev7mo) || '0.00'}</td>
            <td>{closed(prev6mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev6mo) || '0.00'}</td>
            <td>{closed(prev5mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev5mo) || '0.00'}</td>
            <td>{closed(prev4mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev4mo) || '0.00'}</td>
            <td>{closed(prev3mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev3mo) || '0.00'}</td>
            <td>{closed(prev2mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev2mo) || '0.00'}</td>
            <td>{closed(prev1mo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(prev1mo) || '0.00'}</td>
            <td>{closed(currentMo).length}</td>
            <td colSpan='2' style={dollarStyle}>$ {closedAmount(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={boldStyle}>Disposition (Net Numbers)</td>
            <td colSpan='36'></td>
          </tr>
          <tr>
            <td style={headerStyle}>Paid Medicaid</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev11mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev10mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev9mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev8mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev7mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev6mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev5mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev4mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev3mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev2mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(prev1mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicaidAmount(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Paid Medicare</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev11mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev10mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev9mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev8mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev7mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev6mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev5mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev4mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev3mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev2mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(prev1mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {medicareAmount(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Paid Commercial</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev11mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev10mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev9mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev8mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev7mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev6mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev5mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev4mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev3mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev2mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(prev1mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {commercialAmount(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={boldStyle}></td>
          </tr>
          <tr>
            <td style={headerStyle}>Disc Bad Debt</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev11mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev10mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev9mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev8mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev7mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev6mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev5mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev4mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev3mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev2mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(prev1mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {discBadDebt(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Balance of Remaining Referrals</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev11mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev10mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev9mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev8mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev7mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev6mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev5mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev4mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev3mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev2mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(prev1mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {balance(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={boldStyle}></td>
          </tr>
          <tr>
            <td style={headerStyle}>Totals<div style={subTextStyle}>(Bad Debt+Disposition+Balance Remain Referrals)</div></td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev11mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev10mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev9mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev8mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev7mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev6mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev5mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev4mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev3mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev2mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(prev1mo) || '0.00'}</td>
            <td colSpan='3' style={dollarStyle}>$ {totals(currentMo) || '0.00'}</td>
          </tr>
          <tr>
            <td style={headerStyle}>Percentage of Resolved Placements/Liquidation Rate</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev11mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev10mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev9mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev8mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev7mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev6mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev5mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev4mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev3mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev2mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(prev1mo) || '0'} %</td>
            <td colSpan='3' style={percentStyle}>{liquidationRate(currentMo) || '0'} %</td>
          </tr>
          <tr>
            <td style={headerStyle}>Percentage of Remaining Balance of Net Placement</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev11mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev10mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev9mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev8mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev7mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev6mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev5mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev4mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev3mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev2mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(prev1mo)} %</td>
            <td colSpan='3' style={percentStyle}>{remBalancePercent(currentMo)} %</td>
          </tr>
        </tbody>

      </Table>
      <br />
      <Table style={miniTableStyle}>
        <thead>
          <tr><td style={cellStyle} colSpan='4'>Total Cash Recovery by Placement Year</td></tr>
          <tr><td style={cellStyle}>{moment().subtract(2, 'year').format("YYYY")}</td>
            <td style={cellStyle}>{moment().subtract(1, 'year').format("YYYY")}</td>
            <td style={cellStyle}>{moment().format("YYYY")}</td>
            <td style={cellStyle}>TOTAL</td></tr>
        </thead>
        <tbody>
          <tr>
            <td style={percentStyle}>$ {prev2ytotal.toFixed(2)}</td>
            <td style={percentStyle}>$ {prev1ytotal.toFixed(2)}</td>
            <td style={percentStyle}>$ {currentytotal.toFixed(2)}</td>
            <td style={percentStyle}>$ {totalYears.toFixed(2)}</td>
          </tr>
        </tbody>
    </Table>
    <br /> <br /> <br />
    </>
  )
}