import React, { useState } from 'react'
import ClaimTable from '../ClaimTable'
import { orderBy } from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { Spinner } from '@blueprintjs/core'

const sortByAttribute = (items, attr, order) => {
  if (attr === "dueDate" || attr === "admitDOSDate"|| attr === "dischargeDate") {
    items.map(i => i.date = Date.parse(i[attr]))
    return orderBy(items, [(o) => o["date"]], [order])
  }
  // else if (attr === "account") {
  //   items.forEach(function (part, index) {
  //     part.account = parseInt(part.account)
  // })}
  return orderBy(items, [(o) => o[attr] && (o[attr].id || o[attr])], [order])
}

const FRAGMENT = gql`
  fragment DetailFragment on ClaimDetails {
    claim
    account
      hospital {
        name
        id
      }
      status {
        name
        id
      }
      assignedTo {
        name
        id
      }
      dob
      dueDate
      firstName
      lastName
      admitDOSDate
      dischargeDate
  }
`

const QUERY = gql`
  ${FRAGMENT}
  query {
    claims {
  ...DetailFragment
    }
  }`

export default (props) => {
  const { loading, data } = useQuery(QUERY, {
    fetchPolicy: 'network-only'
  })
  const [sortValue, updateSortValue] = useState('dueDate')
  const [sortOrder, updateOrder] = useState('asc')
  const updateSortOrder = (s) => {
    const defaultSortOrder = 'asc'
    const newSortOrder = 'desc'
    if (s === sortValue) {
      updateOrder(sortOrder === defaultSortOrder ? newSortOrder : defaultSortOrder)
    } else {
      updateOrder(defaultSortOrder)
    }

    updateSortValue(s)
  }
  if (loading) {
    return <Spinner />
  }
  return (<>
    <h1>All Claims</h1>
    <ClaimTable
      sortValue={sortValue}
      sortOrder={sortOrder}
      updateSortOrder={updateSortOrder}
      items={sortByAttribute(data ? data.claims : [], sortValue, sortOrder)}
    /></>
  )
}