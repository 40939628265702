import React, { useState } from 'react'
import { Table } from '../../../elements'
import { Formik, FieldArray, Form, Field } from 'formik'
import { RadioGroup, Radio, Divider, Spinner, Button } from '@blueprintjs/core'
import { FormInput } from '../../../elements/Input'
import { SaveButton } from '../../../elements'
import './../../../index.css'
import { FormikInput } from '../../../elements/Formik'
import { ClaimSchema_ } from '@ca/validation'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import DateRangePicker from 'react-daterange-picker'
import "react-daterange-picker/dist/css/react-calendar.css";

const FRAGMENT = gql`
  fragment WriterFragment on ClaimDetails {
    claim
    status {
        name
        id
      }
    writerPaid
    paidWriterDate
    paidCADate
    revenue
    invoiced
    invoicedDate
    invoiceNumber
    account
    referralDate
    closeDate
  }`

const QUERY = gql`
  query($hospitalId: [Int], $statusId: [Int], $paid: [Int], $writerPaid: [Int], $invoiced: [Int], $startDate: [String], $endDate: [String]) {
    appeal_statuses {
      id
      name
    }
    claims(hospitalId: $hospitalId, statusId: $statusId, paid: $paid, writerPaid: $writerPaid, invoiced: $invoiced, startDate: $startDate, endDate: $endDate) {
      claim
      payer
      hospital {
        id
        name
      }
      account
      assignedTo {
        name
        id
        email
      }
      status {
        name
        id
      }
      claim
    writerPaid
      paidWriterDate
      paid
      invoiced
      closeDate
    }
  }
`
const MUTATION = gql`
  mutation UpdateWriterPaidClaim($id: ID!, $claim_details: ClaimInput!) {
    updateWriterPaidClaim(id: $id, claim_details: $claim_details) {
      ...WriterFragment
    }
  }
  ${FRAGMENT}`


const numberFieldStyle = {
    width: '100px',
    textAlign: 'right'
}

const arrayIze = (vals) => {
    const arrayFlds = ['hospitalId', 'statusId', 'paid', 'invoiced']
    arrayFlds.forEach(f => {
        if (vals[f] !==undefined && vals[f] !== null && !Array.isArray(vals[f]))
            vals[f] = [vals[f]]
    })
    return vals
}

export default () => {

    const [filterVals, updateFilterVals] = useState({ writerPaid: -1 })
    const [selection, updateSelection] = useState({ writerPaid: -1, dateRange: null })
    const [error, updateError] = useState(false)
    const { loading, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: arrayIze(filterVals)
    })
    const [updateWriterPaidClaim] = useMutation(MUTATION)
    const onSubmit = async (props) => {
        await props.claims.map((p, i) => {
            if (p.values[0].writerPaid && props.masterPaidWriterDate) {
                p.values.map((v, i) => {
                    v.paidWriterDate = props.masterPaidWriterDate ? props.masterPaidWriterDate : null
                    v.writerPaid = true
                    let model = ClaimSchema_.cast(v, { stripUnknown: true })
                    updateWriterPaidClaim({
                        variables: {
                            id: v.claim,
                            claim_details: model
                        }
                    })
                })
            } else if (!props.masterPaidWriterDate) {
                updateError(true)
            }
        })
        await clear()
    }

    const headers = ["Paid", "Author", "Appeals Closed", "Appeals Won", "Payment Date"]

    const trueFalseValues = [
        {
            name: 'True',
            id: 1
        },
        {
            name: 'False',
            id: 0
        },
        {
            name: 'All',
            id: -1
        }
    ]

    const filterOnChange = (kv) => {
        if (kv.value && kv.value.type === 'radio') {
            const value = parseInt(kv.value.value)
            let newVals = { ...filterVals, [`${kv.name}`]: value }
            let options = { ...selection, [`${kv.name}`]: value }

            const newState = { ...newVals }
            const newOptions = { ...options }
            updateFilterVals(prv => newState)
            updateSelection(prv => newOptions)
            updateError(false)
        } else {
            let newVals = { ...filterVals, [`startDate`]: kv.start._d, [`endDate`]: kv.end._d }
            let options = { ...selection, [`dateRange`]: kv }

            const newState = { ...newVals }
            const newOptions = { ...options }
            updateFilterVals(prv => newState)
            updateSelection(prv => newOptions)
            updateError(false)
        }
    }

    const clear = () => {
        updateFilterVals({ writerPaid: -1 })
        updateSelection({ writerPaid: -1, dateRange: null })
        updateError(false)
    }

    const updateChecked = (event, handleChange) => {
        const fieldId = parseInt(event.target.name.substring(11))
        data.claims.forEach(function (part, index) {
            this[index].writerPaid = this[index].assignedTo && this[index].assignedTo.id === fieldId ? !this[index].writerPaid : this[index].writerPaid
        }, data.claims);
        updateError(false)
        handleChange(event)
    }


    if (loading || !data) {
        return <Spinner />
    }
    function groupByArray(xs, key) {
        return xs.reduce(function (rv, x) {
            let v = key instanceof Function ? key(x) : x[key];
            let el = rv.find((r) => r && r.key === v);
            if (el) {
                el.values.push(x);
            } else {
                rv.push({ key: v, values: [x] });
            }
            return rv;
        }, []);
    }
    const groupByAuthor = groupByArray(data.claims, 'assignedTo')
    return (
        <>
            <div className='PIfilterStyle'>
                <DateRangePicker
                    value={selection.dateRange}
                    onSelect={filterOnChange} />
                <label className='APfieldStyle'>Paid<RadioGroup selectedValue={selection.writerPaid === 0 || selection.writerPaid === 1 ? selection.writerPaid : -1}
                    onChange={(e) => filterOnChange({
                        name: 'writerPaid',
                        value: e.target || null
                    })} >
                    {trueFalseValues.map(t => { return <Radio key={t.id} label={t.name} value={t.id} /> })}
                </RadioGroup></label>

            </div>
            <Divider />
            <Formik
                initialValues={groupByAuthor && groupByAuthor[0] ? { claims: groupByAuthor, masterWriterPaidDate: null } : []}
                onSubmit={onSubmit}
                render={({ values, handleChange }) => (
                    <Form>
                        <FieldArray name="groupByAuthor"
                            render={arrayHelpers => (
                                <div>
                                    <Table><thead><tr className='PIrowStyle'>
                                        {
                                            headers.map((h, i) => <th className={i === 0 ? 'PIcheckboxLabelStyle' : i === 2 ? 'APheaderStyle' : 'PIheaderStyle'} key={`report-header-${i}`}>{h}</th>)
                                        }
                                    </tr></thead></Table>
                                    {values && values.claims && values.claims.length > 0 ?
                                        (values.claims.map((claim, index) => (
                                            <div className='PIrowStyle' key={index}>
                                                {selection.writerPaid === -1 || !claim.key ? <td name ="" width={50} className='PIcheckboxLabelStyle' id={index} value=''/> :
                                                <Field
                                                    name={`writerPaid.${claim.key ? claim.key.id : "Unassigned"}`}
                                                    id={`writerPaid.${index}`}
                                                    className='PAcheckboxStyle'
                                                    disabled={selection.writerPaid === 1 || selection.writerPaid === -1}
                                                    type="checkbox"
                                                    checked={claim.values[0].writerPaid ? claim.values[0].writerPaid : false}
                                                    onChange={e => updateChecked(e, handleChange)}
                                                /> }
                                                <td>{claim.key ? claim.key.name : "Unassigned"}</td>
                                                <td style={numberFieldStyle}>{claim.values.filter(c => c.status && (c.status.id >= 9)).length || "0"}</td>
                                                <td style={numberFieldStyle}>{claim.values.filter(c => c.status && (c.status.id === 9 || c.status.id === 10 || c.status.id === 11)).length || "0"}
                                                </td>
                                                {selection.writerPaid ? <FormInput
                                                    name={claim.values[0].paidWriterDate ? "paidWriterDate" : "masterPaidWriterDate"}
                                                    id={`paidWriterDate.${index}`}
                                                    className='paDate'
                                                    value={claim.values[0].paidWriterDate ? claim.values[0].paidWriterDate : values.masterPaidWriterDate}
                                                    disabled={selection.writerPaid}
                                                /> : <FormikInput
                                                        name={claim.values[0].writerPaid && values.masterPaidWriterDate ? "masterPaidWriterDate" : `paidWriterDate.${index}`}
                                                        id={`paidWriterDate.${index}`}
                                                        className='paDate'
                                                        type="date"
                                                        disabled={true}
                                                    />}
                                            </div>))) : <div><br /><p>No claims found</p><br /></div>}{selection.writerPaid === 0 && <FormikInput
                                        className='PIinvoiceStyle'
                                        name="masterPaidWriterDate"
                                        id="masterPaidWriterDate"
                                        label="Payment Date"
                                        type="date"
                                        placeholder="MM/DD/YYYY"
                                    />}
                                </div>)} />
                        <SaveButton to="/report/author" /><Button text='Clear all changes' onClick={clear} />
                    </Form>)} />{error && <p className='errorStyle'>Please select one or more authors and enter a payment date.</p>}
        </>
    )
}
