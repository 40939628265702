import React from 'react'
import { useField, useFormikContext } from 'formik'

import {
  FormInput,
  FormSimpleSelect,
  FormSuggest,
  FormTextArea,
  MaskedInput,
  PhoneMaskedInput,
  FormEditableText,
  CurrencyMaskedInput
} from '../Input'

export const useFormik = (name) => {
  const { isSubmitting, setFieldValue, submitCount } = useFormikContext()
  const [field, meta] = useField(name)

  const { error, touched } = meta

  return {
    field,
    meta,
    isSubmitting,
    setFieldValue,
    error: (touched || submitCount > 0) && error
  }
}

export const FormikInput = ({ name, ...props }) => {
  const { field, isSubmitting } = useFormik(name)
  return (
    <FormInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
    />
  )
}

export const FormikCurrencyInput = ({ name, ...props }) => {
  const { field, isSubmitting } = useFormik(name)
  return (
    <CurrencyMaskedInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
    />
  )
}

export const FormikEditableText = ({ name, ...props }) => {
  const { field, isSubmitting, setFieldValue } = useFormik(name)
  return (
    <FormEditableText
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
      onChange={(item) => { return setFieldValue(name, item) }}
    />
  )
}

export const FormikMaskedInput = ({ name, ...props }) => {
  const { field, isSubmitting } = useFormik(name)

  return (
    <MaskedInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
    />
  )
}

export const FormikPhoneMaskedInput = ({ name, ...props }) => {
  const { field, isSubmitting } = useFormik(name)

  return (
    <PhoneMaskedInput
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
    />
  )
}

export const FormikTextArea = ({ name, ...props }) => {
  const { field, isSubmitting } = useFormik(name)

  return (
    <FormTextArea
      {...props}
      {...field}
      disabled={isSubmitting || props.disabled}
    />
  )
}


export const FormikSuggest = ({ name, value, dependent, updateDependent, ...props }) => {
  const { field, isSubmitting, setFieldValue } = useFormik(name)
  const dForm = useFormik(dependent)

  return (
    <FormSuggest
      {...props}
      value={value ? value : field.value}
      onBlur={field.onBlur}
      onChange={(item) => {
        if(dependent) {
          updateDependent(item, dependent, (val) => {
            setFieldValue(dForm.field.name, val)
          })
        }
        return setFieldValue(field.name, item)
      }}
      name={field.name}
      disabled={isSubmitting || props.disabled}
    />
  )
}


export const FormikSimpleSelect = ({ name, ...props }) => {
  const { field, isSubmitting, setFieldValue } = useFormik(name)

  return (
    <FormSimpleSelect
      {...props}
      {...field}
      onChange={(item) => {
        console.log(`Setting: ${name}=>${JSON.stringify(item)}`)
        setFieldValue(name, item)
        if (props.onChange)
          props.onChange(item, setFieldValue)
      }}
      disabled={isSubmitting || props.disabled}
    />
  )
}


