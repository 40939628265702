import React, { useState } from "react";
import { Table } from "../../../elements";
import { Formik, FieldArray, Form, Field } from "formik";
import { Divider, RadioGroup, Radio, Spinner } from "@blueprintjs/core";
import { FormSimpleSelect } from "../../../elements/Input";
import { SaveButton, ButtonLink } from "../../../elements";
import "./../../../index.css";
import { FormikInput } from "../../../elements/Formik";
import { ClaimSchema_ } from "@ca/validation";
import { gql } from "apollo-boost";
import { useQuery, useMutation } from "@apollo/react-hooks";
import moment from "moment";
import { authProvider } from '../../../auth'
import download from 'downloadjs'

const API_URL = process.env.REACT_APP_API_URL
const isLocal = window.location.hostname === 'localhost'

// Create our number formatter.
var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatDollarAmt = (amt = 0) => formatter.format(amt);

const FRAGMENT = gql`
  fragment InvoicedFragment on ClaimDetails {
    claim
    status {
      name
      id
    }
    paid
    paidCADate
    invoiced
    invoicedDate
    invoiceNumber
    account
    referralDate
    medicalRecord
    firstName
    lastName
    payer
  }
`;
console.log(API_URL)
const INVOICE_QUERY = gql`
  query($hospitalId: Int) {
    hospitals {
      id
      name
    }
    invoices(hospitalId: $hospitalId) {
      hospital {
        id
        name
      }
      invoiceNumber
      invoicedDate
      claimCount
      totalRevenue
    }
  }
`;

const getInvoiceUrl = (invNbr) => `${!isLocal ? API_URL : ''}/claims/xlsx/${invNbr}`
const downloadInvoice = async (invNbr) => {
    const {idToken} = await authProvider.getIdToken();
    fetch(getInvoiceUrl(invNbr), {
      headers: {
        'Authorization': `Bearer ${idToken.rawIdToken}`,
        'Content-Type': 'application/json',

        'Accept': 'application/json'
      }
    })
    .then(res => res.blob())
    .then(blob => download(blob, `Invoice_${invNbr}.xlsx`))
}

export default ({ trueFalseValues }) => {
  const [filterVals, updateFilterVals] = useState({
    hospital: {},
  });
  const [selection, updateSelection] = useState({});
  const [invoices, updateInvoices] = useState([]);

  const { loading, data, refetch } = useQuery(INVOICE_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      hospitalId: filterVals.hospital.id,
    },
    onCompleted: (qdata) => {
      updateInvoices(qdata.invoices);
    },
  });

  const updateFilter = (fld, val) => {
    updateFilterVals({
      ...filterVals,
      [`${fld}`]: val,
    });
  };

  if (loading || !data) {
    return <Spinner />;
  }
  const addDefault = (op) => {
    const defaultVal = {
      id: -1,
      name: "Select...",
    };
    let optionsToReturn = op;
    if (!op.find((o) => o.id === -1)) {
      optionsToReturn.unshift(defaultVal);
    }

    return optionsToReturn;
  };

  return (
    <>
      <div className="PIfilterStyle">
        <FormSimpleSelect
          label="Hospital"
          className="PIfieldStyle"
          onChange={(e) => updateFilter("hospital", e)}
          items={data.hospitals ? addDefault(data.hospitals) : []}
          value={filterVals.hospital || -1}
        />
      </div>
      <Divider />
      <Table>
        <thead className="grayHeader">
          <tr>
            <th>Hospital</th>
            <th>Invoice Number</th>
            <th>Invoice Date</th>
            <th>Claims</th>
            <th>Revenue</th>
            <th>Download</th>
          </tr>
        </thead>

        {invoices && invoices.length > 0 ? (
          <tbody>
            {invoices.map((invoice, index) => (
              <tr key={index}>
                <td>{invoice.hospital.name}</td>
                <td>{invoice.invoiceNumber}</td>
                <td>
                  {moment(invoice.invoicedDate).format("MM/DD/YYYY")}
                </td>
                <td>{invoice.claimCount}</td>
                <td>{formatDollarAmt(invoice.totalRevenue)}</td>
                <td>
                    <a href="#!" onClick={() => downloadInvoice(invoice.invoiceNumber)}  target="_blank">
                        Download
                    </a>
                </td>
              </tr>
            ))}
          </tbody>
        ) : null}
      </Table>
    </>
  );
};
