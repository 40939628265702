import React, {useState} from 'react'
import { Spinner } from '@blueprintjs/core'
import './../../../index.css'
import { FormSimpleSelect } from '../../../elements/Input'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import { Table } from '../../../elements'
import moment from 'moment'
import PayrollTable from '../PayrollTable'

const QUERY = gql`
  query($hospitalId: [Int], $statusId: [Int], $startDate: [String], $endDate: [String], $assignedToId: [Int]) {
    appeal_statuses {
      id
      name
    }
    assignedTos {
        name
        id
      }
    claims(hospitalId: $hospitalId, statusId: $statusId, startDate: $startDate, endDate: $endDate, assignedToId: $assignedToId) {
      claim
      assignedTo {
        name
        id
      }
      status {
        name
        id
      }
    writerPaid
    assignedDate
    paidWriterDate
    paid
    invoiced
    closeDate
    firstSubmitDate
    secondSubmitDate
    thirdSubmitDate
    firstDecisionDate
    secondDecisionDate
    thirdDecisionDate
    }
  }
`
const filterStyle = {
    marginRight: '10px'
}

const filterContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        padding: "20px",
        justifyContent: 'space-between'
}

const tableStyle = {
    width: '50%'
}

const DEFAULT = {
    id: -1,
    name: 'Select...'
}

const addDefault = (op) => {
    let optionsToReturn = op
    if (!op.find(o => o.id === -1)) {
        optionsToReturn.unshift(DEFAULT)
    }
    return optionsToReturn
}

const arrayIze = (vals) => {
    const arrayFlds = ['hospitalId', 'statusId', 'paid', 'invoiced']
    arrayFlds.forEach(f => {
        if (vals[f] !==undefined && vals[f] !== null && !Array.isArray(vals[f]))
            vals[f] = [vals[f]]
    })
    return vals
}

export default () => {
    const [filterVals, updateFilterVals] = useState({})
    const { loading, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: arrayIze(filterVals)
    })

    const [selection, updateSelection] = useState({})
    const [allClaims, updateAllClaims] = useState(false)
    if (loading) {
        return <Spinner />
    }
    const filterOnChange = async (kv) => {
        if (kv.name === 'time') {
            kv.value.id === 1 ? updateAllClaims(false) : updateAllClaims(true)
        }
        console.log(kv)
        let newVals = { ...filterVals, [`${kv.name}Id`]: kv.value.id }
        let options = { ...selection, [`${kv.name}`]: { name: kv.value.name, id: kv.value.id } }

        const newState = { ...newVals }
        const newOptions = { ...options }

        await updateFilterVals(prv => newState)
        await updateSelection(prv => newOptions)
    }

    const years = (new Array(13)).fill(0).map( (v, i) => moment().subtract(i,'month').startOf('month') )

    const claimsAssigned = data ? data.claims.filter(c => c.assignedDate && c.assignedTo) : null;
    const claimsDecided = data ? data.claims.filter(c => c.status && c.status.id > 8) : null;
    const claimsWon = data ? data.claims.filter(c => c.status && (c.status.id === 9 || c.status.id === 10 || c.status.id === 11)) : null;
    const claimsUnpaid = data && claimsWon ? claimsWon.filter(c => !c.closeDate) : null;
    const addDecision = (claims) => {
        if (claims && claims.length > 0) {
            for(let i = 0; i < claims.length; i++)
                if (claims[i].thirdDecisionDate) claims[i].decision = claims[i].thirdDecisionDate;
                else if (claims[i].secondDecisionDate) claims[i].decision = claims[i].secondDecisionDate;
                else claims[i].decision = claims[i].firstDecisionDate;
            }
            return claims
    }
    const filteredClaims = [claimsAssigned, claimsDecided, claimsWon, claimsUnpaid]
    const totals = Array(filteredClaims.length).fill(0)

    console.log('sl', selection)
    return (
        <>
        <div style={filterContainerStyle}>
            <FormSimpleSelect
                label="Writer"
                key='assignedTo'
                style={filterStyle}
                onChange={(e) => filterOnChange({
                    name: 'assignedTo',
                    value: e || null
                })}
                items={data ? addDefault(data.assignedTos) : []}
                value={selection.assignedTo || DEFAULT}
                />
            <FormSimpleSelect
                label="Time Frame"
                key='timeFrame'
                onChange={(e) => filterOnChange({
                    name: 'time',
                    value: e || null
                })}
                items={[{id: 1, name: 'Last 12 months'}, {id: 2, name: 'All Time'}]}
                value={selection.time}
            />          </div>
            {!allClaims &&
            <Table style={tableStyle}>
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Appeals Assigned</th>
                        <th>Appeals Decided</th>
                        <th>Appeals Won</th>
                        <th>Unpaid Wins</th>
                    </tr>
                </thead>
                <tbody>
                    {years.map((yr, i) => {
                        const prev = i > 0 ? years[i - 1] : moment()
                        return <tr key={i}>
                            <td>{yr.format('MM/YYYY')}</td>
                            {
                                filteredClaims.map((cl, n) => {
                                    const count = cl && cl.length > 0 ?
                                        cl.filter(c => moment(c.assignedDate).isAfter(yr) && moment(c.assignedDate).isBefore(prev)).length : 0
                                    totals[n] += count
                                    return <td>{count}</td>
                                })
                            }
                            </tr>
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th>Totals: </th>
                        { totals.map( t => <th>{ t }</th> ) }
                    </tr>
                </tfoot>
            </Table>}
            {allClaims && <PayrollTable
                            assigned={claimsAssigned}
                            decided={claimsDecided}
                            won={claimsWon}
                            style={tableStyle} addDecision={addDecision}/>}
        </>
    )
}