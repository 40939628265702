import React from 'react'
import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Spinner } from '@blueprintjs/core'
import PerformanceTable from '../PerformanceTable';

const QUERY = gql`
  query($hospitalId: [Int], $accountTypeId: [Int], $finClassId: [Int], $statusId: [Int], $appealDenialId: [Int]) {
    hospitals {
      id
      name
    }
    finClasses {
      id
      name
    }
    accountTypes {
      id
      name
    }
    appeal_statuses {
      id
      name
    }
    appealDenials {
      id
      name
    }
    claims(hospitalId: $hospitalId, accountTypeId: $accountTypeId,
    finClassId: $finClassId, statusId: $statusId,
    appealDenialId: $appealDenialId) {
      claim
      payer
      hospital {
        id
        name
      }
      account
      appealDenial {
        id
        name
      }
      finClass {
        id
        name
      }
      assignedDate
      dueDate
      status {
        id
        name
      }
      accountType {
        id
        name
      }
      unitsDenied
      paid
      unitsWon
      closeDate
      concurrentDate
      externalAppDate
      revenue
      referralDate
      cannotBeAppealed
      expectedRevenue
      noAppealType {
          id
          name
      }
    }
  }
`

export default () => {
  const { loading, data } = useQuery(QUERY , {
    fetchPolicy: 'network-only'
  })

  if(loading || !data) {
    return <Spinner />
  }
  const inpatientClaims = data.claims.filter(c => c.accountType && c.accountType.id === 1)
  const outpatientClaims = data.claims.filter(c => c.accountType && c.accountType.id === 2)
  return (
    <>
      <h1>Performance Report</h1>
      <Tabs defaultActiveKey="inpatient" id="denialReferral">
        <Tab eventKey="inpatient" title="Inpatient">
            <PerformanceTable data={data} claims={inpatientClaims}/>
        </Tab>
        <Tab eventKey="outpatient" title="Outpatient">
        <PerformanceTable data={data} claims={outpatientClaims}/>
        </Tab>
      </Tabs>
    </>
  )
}
