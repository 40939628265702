import React from 'react'
import { HTMLTable } from '@blueprintjs/core'

export const Table = ({ children, id, style }) => {

    return (
      <HTMLTable 
        id={id}
        interactive
        striped
        style={Object.assign({ width: '100%' }, style)}
      >
        {children}
      </HTMLTable>
    )
  }