import React from 'react'
import { Form } from '../../../elements/Form'
import { Card } from '@blueprintjs/core'
import { FormikSimpleSelect} from '../../../elements/Formik'
import { SaveButton } from '../../../elements'

export default ({ values = {},
    errors,
    unassigneds,
    hospitals,
    ...props
}) => {
    return <Form><Card>
        <FormikSimpleSelect
            name="account"
            id="account"
            label="Account"
            items={unassigneds}
            nullable
        />
        <FormikSimpleSelect
            name="hospital"
            id="hospital"
            label="Hospital"
            items={hospitals}
            nullable
        />
    </Card>
    <div className="buttonSetStyle">
    {errors.account && <div className='errorStyle'>{errors.account.name}</div>}
    <SaveButton type="submit" to="/" text='Assign' /></div>
        <p><b>NOTE</b>: <br/>Leaving the <b>Hospital</b> field blank will remove the connection between the selected account and any hospital.</p>
        <p>Only users that have been assigned the "Hospital" role in the Azure Portal will display in the <b>Account</b> field.<br/>
        To assign the "Hospital" role to a user, access the <a target="_blank" rel="noopener noreferrer" href="https://portal.azure.com/#blade/Microsoft_AAD_IAM/ManagedAppMenuBlade/Users/appId/f2cfa562-ec0c-4492-9cdc-60d60e8ec6de/objectId/7737b22a-4f03-410d-a325-1486912f2a05">Azure Portal</a>.</p>

    </Form>
}