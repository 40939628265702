import React from 'react'
import { Table } from '../../../elements'
import moment from 'moment'

var dateStart = moment('2020-1-01');
var dateEnd = moment().subtract(1, 'months');
var timeValues = [];

while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
   timeValues.push(dateStart.format('YYYY-MM'));
   dateStart.add(1,'month');
}


export default ({  assigned, decided, won, style, addDecision, ...props}) => {
    let rows = []
    for (let i = 0; i < timeValues.length; i++) {
        rows.push(<tr key={i}><td>{timeValues[i]}</td>
            <td>{assigned && assigned.length > 0 ? assigned.filter(c => moment(c.assignedDate).isAfter(timeValues[i]) && moment(c.assignedDate).isBefore(timeValues[i+1])).length : '0'}</td>
            <td>{decided && decided.length > 0 ? addDecision(decided).filter(c => moment(c.decision).isAfter(timeValues[i]) && moment(c.decision).isBefore(timeValues[i+1])).length : '0'}</td>
            <td>{won && won.length > 0 ? addDecision(won).filter(c => moment(c.decision).isAfter(timeValues[i]) && moment(c.decision).isBefore(timeValues[i+1])).length : '0'}</td>
    </tr>)
    }
    return (
        <Table style={style}>
        <thead><tr>
            <th>{timeValues[0]}</th>
            <th>Appeals Assigned</th>
            <th>Appeals Decided</th>
            <th>Appeals Won</th>
        </tr></thead>
        <tbody>{rows}
        <tr><td>{moment().format('MM/YYYY')}</td>
                <td>{assigned && assigned.length > 0 ? assigned.filter(c => moment(c.assignedDate).isAfter(moment().startOf('month'))).length : '0'}</td>
                <td>{decided && decided.length > 0 ? addDecision(decided).filter(c => moment(c.decision).isAfter(moment().startOf('month'))).length : '0'}</td>
                <td>{won && won.length > 0 ? addDecision(won).filter(c => moment(c.decision).isAfter(moment().startOf('month'))).length : '0'}</td>
            </tr></tbody>
        </Table>
    )}