import React from 'react'
import { EditableText as CoreEditableText, FormGroup, InputGroup, HTMLSelect, TextArea, Icon, Menu, MenuItem, TagInput, Popover, Button } from '@blueprintjs/core'
import { Suggest, MultiSelect } from '@blueprintjs/select'
import { default as CoredMaskedInput } from 'react-text-mask'
import { useField, useFormikContext } from 'formik'
import { withProps } from 'recompose'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const useFormik = (name) => {
  const { isSubmitting, setFieldValue } = useFormikContext()
  const [field, meta] = useField(name)

  return {
    field,
    meta,
    isSubmitting,
    setFieldValue
  }
}

const inputStyle = {
  minWidth: "150px !important",
  paddingRight: "15px"
}

export const FormTextArea = ({ label, placeholder, name, value = '', ...props }) => {
  const { setFieldValue } = useFormik(name);
  const handleChange = (evt) => {
    setFieldValue(name, evt.target.value);
    }
  return (
    <FormGroup
    label={label}
    style={inputStyle}
    >
    <TextArea
      fill
      onChange={handleChange}
      growVertically={false}
      value={value || ''}
      {...props}
    />
  </FormGroup>
  )
}

export const FormInput = ({ label, placeholder, name, value = '', width, ...props }) => {
  const { setFieldValue } = useFormik(name);
  const handleChange = (evt) => {
    setFieldValue(name, evt.target.value);
    }
  return (
    <FormGroup
      label={label}
      style={inputStyle}
    >
      <InputGroup
        noValidate
        placeholder={placeholder}
        onChange={handleChange}
        value={value ? value : ''}
        {...props}
      />
    </FormGroup>)}

export const FormSuggest = ({ disabled, style={}, label, items = [], onBlur, onChange, value, ...props }) => {
  return (
    <div style={style}>
      <FormGroup
        disabled={disabled}
        label={label}
      >
        <Suggest
          fill
          items={items}
          selectedItem={value}
          inputProps={{
            ...props,
            onBlur,
            autoComplete: 'off',
            disabled
          }}
          onItemSelect={onChange}
        />
      </FormGroup>
    </div>
  )
}

const toOptions = (items = []) => items.map(({ id, name }) => ({ label: name, value: id }))
export const FormSimpleSelect = ({ items, label, nullable, onChange, value, ...props }) => {
  const id = value && value.id
  const mapped = toOptions(items)
  const options = nullable ? [{ label: '', value: '' }].concat(mapped) : mapped


  const handleChange = (evt) => {
    const item = items.find(o => o.id === parseInt(evt.target.value, 10) || o.id === evt.target.value);
    onChange(item);
  }

  return (
    <FormGroup
      label={label}
    >
      <HTMLSelect
        fill
        autoComplete="off"
        options={options}
        onChange={handleChange}
        value={id ? id : ''}
        {...props}
      />
    </FormGroup>
  )
}

export const FormMultiSelect = ({ items=[], label, nullable, onChange, value = [], idField = "id", nameField = "name", ...props }) => {
  let actives = items.reduce((c, item) => {
    const active = value.length &&
          (value.includes(item) ||
            (item && item[idField] !== undefined &&
              (
                value.includes(item[idField]) ||
                value.includes(item[idField] + '') ||
                (parseInt(item[idField]) && value.includes(parseInt(item[idField])))
              )
      ))
    if (active)
      c.push(item)
    return c
  }, [])

  const PopMenu = ({ activeItems }) => {
    return <Menu>
      {items.map((item, idx) => {
        const active = activeItems.length && item && (item[idField] !== undefined ?
          activeItems.some(a => a[idField] == item[idField]) :
          activeItems.includes(item))
        const itemKey = item && item[idField] ? item[idField] : idx
        const itemName = item && item[nameField] ? item[nameField] : item
        console.log(itemKey, idx)
         return <MenuItem
          key={itemKey ? itemKey : idx }
          text={itemName}
          icon={ active ? 'tick' : 'blank' }
          shouldDismissPopover={false}
          onClick={() => onChange(item)}
        />
      }
      )}
    </Menu>
  }

  return (
    <FormGroup label={label}>
      <Popover content={<PopMenu activeItems={actives} />} fill={true} >
        <Button fill={true} rightIcon="double-caret-vertical"
          style={{ marginLeft: 0 }} alignText="left"
          title={actives.map(a=>(a[nameField] || a)).join("\n")}>{actives.length ?
            actives.length > 1 ? `(${actives.length} selected)` : (actives[0][nameField] || actives[0])
           : 'Select...'}</Button>
      </Popover>
    </FormGroup>
  )

}

export const MaskedInput = ({ disabled, label, placeholder, name, mask, value = '', ...props }) => {
  const { setFieldValue } = useFormik(name);
  const handleChange = (evt) => {
    setFieldValue(name, evt.target.value);
    }
  return (
    <FormGroup
      disabled={disabled}
      label={label}
    >
      <CoredMaskedInput
        guide={false}
        mask={mask}
        value={value}
        {...props}
        render={(ref, props) =>
          <InputGroup
            noValidate
            disabled={disabled}
            placeholder={placeholder}
            inputRef={ref}
            onChange={handleChange}
            {...props}
          />
        }
      />
    </FormGroup>
  )
}

export const FormEditableText = ({ errorText, ...props }) =>  { return(
  <span>
    <CoreEditableText
      multiline={false}
      minWidth={120}
      {...props}
    />

    <Icon
      icon="edit"
    />
  </span>
)}

export const PhoneMaskedInput = withProps({
  mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
})(MaskedInput)

export const DateMaskedInput = withProps({
  mask: [/[0-9]/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
})(MaskedInput)

const currencyMask = createNumberMask({
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
})

export const CurrencyMaskedInput = withProps({
  mask: currencyMask
})(MaskedInput)