import React from 'react'
import { HTMLTable } from '@blueprintjs/core'
import { Icon, NavButtonLink } from '../../elements'
import moment from 'moment'


const getSortIcon = (column, sortValue, sortOrder) => {
    if (column === sortValue) {
        return sortOrder === 'asc' ? <Icon name='chevron-down' /> : <Icon name='chevron-up' />
    }
}

const linkStyle = {
    color: '#007bff',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    paddingTop: 0,
    marginTop: '-2px',
    marginLeft: 0,
    paddingLeft: 0
}

export default ({ updateSortOrder, sortValue, sortOrder, submitClaim, items = [] }) => {
    return (
        <HTMLTable striped>
            <thead>
                <tr>
                    <th className="sortHeader" onClick={() => updateSortOrder('hospital')}>Hospital {getSortIcon('hospital', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('account')}>Account # {getSortIcon('account', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('dueDate')}>Due Date {getSortIcon('dueDate', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('lastName')}>Patient Name {getSortIcon('lastName', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('admitDOSDate')}>Admit Date {getSortIcon('admitDOSDate', sortValue, sortOrder)}</th>
                    <th className="sortHeader" onClick={() => updateSortOrder('dischargeDate')}>Discharge Date {getSortIcon('dischargeDate', sortValue, sortOrder)}</th>
                    <th className="plainHeader" ></th>
                    <th className="plainHeader" ></th>
                </tr>
            </thead>

            <tbody>
                {items.map(item => {
                    const admitDate = item.admitDOSDate && moment(item.admitDOSDate).format("MM-DD-YYYY")
                    const hospital = item.hospital ? item.hospital.name : null
                    const link = `${item.lastName}, ${item.firstName} - ${admitDate} - ${hospital}`
                    return (
                        <tr key={item.claim}>
                            <td>{item.hospital ? item.hospital.name : ''}</td>
                            <td>{item.account}</td>
                            <td>{item.dueDate}</td>
                            <td>{item.lastName}, {item.firstName}</td>
                            <td>{item.admitDOSDate}</td>
                            <td>{item.dischargeDate}</td>
                            <td>{item.status.id === 4 ? 
                            <NavButtonLink style={linkStyle} to="/" onClick={(e) => submitClaim(item.claim)}>Mark Ready For Submission</NavButtonLink>
                            : 'Claim Submitted'}</td>
                            <td><a target="_blank" rel="noopener noreferrer" href={"https://centralizedappealscom.sharepoint.com/:f:/r/Shared%20Documents/" + link}>
                                Open Workspace</a></td>
                        </tr>
                    )
                })}
            </tbody>
        </HTMLTable>
    )
}