import * as Yup from 'yup';
export default Yup.object().shape({
  hospital: Yup.object().shape({
    id: Yup.mixed(),
    name: Yup.string()
  }),
  account: Yup.object().shape({
    id: Yup.mixed(),
    name: Yup.string().label('Account').required('**Please choose an account to assign**')
  })
});