import React, { useState } from 'react'
import { gql } from 'apollo-boost'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import { Table, Icon } from '../../../elements'
import ReportFilters from '../ReportFilters'
import { Divider, Spinner } from '@blueprintjs/core'
import './index.css'
import moment from 'moment'
import { orderBy } from 'lodash'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const FILTER_QUERY = gql`
  query {
    hospitals {
      id
      name
    }
    finClasses {
      id
      name
    }
    denialTypes {
      id
      name
    }
    careLevels {
      id
      name
    }
    accountTypes {
      id
      name
    }
    appeal_statuses {
      id
      name
    }
    appealDenials {
      id
      name
    }
    payers
    users{
      id
      name
    }
  }
`

const QUERY = gql`
  query($hospital: [Int], $accountType: [Int], $finClass: [Int],
    $denialType: [Int],$status: [Int], $appealDenial: [Int], $referralDate: [String],
    $revenue: [Int], $openClosed: [String], $payer: [String], $assignedTo: [Int], $submissionStatus: [String], $careLevel: [Int]) {

    claims(hospitalId: $hospital, accountTypeId: $accountType,
    finClassId: $finClass, statusId: $status, denialTypeId: $denialType,
    appealDenialId: $appealDenial, referralDate: $referralDate, revenue: $revenue,
      openClosed: $openClosed, payer: $payer, assignedToId: $assignedTo, submissionStatus: $submissionStatus, careLevelId: $careLevel ) {
      claim
      payer
      hospital {
        id
        name
      }
      account
      appealDenial {
        id
        name
      }
      referralDate
      admitDOSDate
      dischargeDate
      admitDoctor
      assignedTo {
        id
        name
      }
      finClass {
        id
        name
      }
      denialType {
        id
        name
      }
      careLevel {
        id
        name
      }
      assignedDate
      dueDate
      status {
        id
        name
      }
      accountType {
        id
        name
      }
      firstName
      lastName
      unitsDenied
      unitsWon
      closeDate
      expectedRevenue
      revenue
      firstSubmitDate
      firstDecisionDate
      secondSubmitDate
      secondDecisionDate
      thirdSubmitDate
      thirdDecisionDate
      updated
    }
  }
`


const tableStyle = {
  border: 'none',
  marginTop: 30
}

const getSortIcon = (column, sortValue, sortOrder) => {
  if (column === sortValue) {
      return sortOrder === 'asc' ? <Icon name='chevron-down'/> : <Icon name='chevron-up'/>
  }
}

const sortByAttribute = (items, attr, order) => {
  if (attr === "dueDate" || attr === "admitDOSDate"|| attr === "dischargeDate" || attr === "updated" || attr === "closeDate") {
    items.map(i => i.date = Date.parse(i[attr]))
    return orderBy(items, [(o) => o["date"]], [order])
  }
  return orderBy(items, [(o) => o[attr] && (o[attr].id || o[attr])], [order])
}

const createLink = ({value}) => {
 return JSON.stringify(value)
}

const formatNumber = ({ value }) => Number(value).toFixed(2)

const formatCurrency = ({ value }) => "$" + formatNumber({value})

const getField = (field) => (({ value={} }) => value && value[field] ? value[field] : '' )

const getSharepointLink = (d) => {
  const admitDate = d.admitDOSDate && moment(d.admitDOSDate).format("MM-DD-YYYY")
  const hospital = d.hospital ? d.hospital.name : null
  return `https://centralizedappealscom.sharepoint.com/:f:/r/Shared%20Documents/${d.lastName}, ${d.firstName} - ${admitDate} - ${hospital}`
}

const nameComparator = (valueA, valueB, nodeA, nodeB, isInverted) => {
  if (!valueA || !valueB) {
    if(valueA == valueB) return 0
    if (!valueA) return -1;
    if (!valueB) return 1;
  }
  if (valueA.name && valueB.name)
    return valueA.name.localeCompare(valueB.name)

  // return isInverted ? valueA.name.trim().localeCompare(valueB.name.trim()) : valueB.name.trim().localeCompare(valueA.name.trim())
}

const DataTable = ({rows}) => {
  const columns = [
    { key: 'updated', name: 'Updated' },
    { key: 'payer', name: 'Payer' },
    { key: 'hospital', name: 'Hospital', valueFormatter: getField('name'), comparator : nameComparator },
    {
      key: 'account',
      name: 'Account #',
      cellRenderer: (params) => `<a href="/claim/edit/${params.value}" target="_blank">${params.value}</a>
      &nbsp;&nbsp;<a target="_blank" href="${getSharepointLink(params.data)}">(Dir)</a>`
    },
    { key: 'lastName', name: 'Patient Name', valueFormatter: ({data, value})=> `${value}, ${data.firstName}`  },
    { key: 'finClass', name: 'Financial Class', valueFormatter: getField('name'), comparator: nameComparator },
    { key: 'careLevel' , name: 'Level Of Care', valueFormatter: getField('name'), comparator: nameComparator},
    { key: 'denialType', name: 'Denial Type', valueFormatter: getField('name'), comparator : nameComparator },
    { key: 'referralDate', name: 'Referral Date' },
    { key: 'admitDOSDate', name: 'Admit Date' },
    { key: 'dischargeDate', name: 'Discharge Date' },
    { key: 'admitDoctor', name: 'Admit Doctor' },
    { key: 'assignedTo', name: 'Assigned To', valueFormatter: getField('name'), comparator: nameComparator },
    { key: 'assignedDate', name: 'Assigned Date' },
    { key: 'dueDate', name: 'Due Date' },
    ...['First', 'Second', 'Third'].reduce((c, s) => {
      return c.concat([{
          key: `${s.toLowerCase()}SubmitDate`,
          name : `${s} Submit Date`
        },
        {
          key: `${s.toLowerCase()}DecisionDate`,
          name : `${s} Decision Date`
        }
      ])
    },[]),
    { key: 'status', name: 'Claim Status', valueFormatter: getField('name'), comparator : nameComparator },
    { key: 'accountType', name: 'Account Type', valueFormatter: getField('name'), comparator : nameComparator },
    { key: 'appealDenial', name: 'Appeal Denial', valueFormatter: getField('name'), comparator : nameComparator },
    { key: 'unitsDenied', name: 'Units Denied' },
    { key: 'unitsWon', name: 'Units Won' },
    { key: 'closeDate', name: 'Close Date' },
    { key: 'expectedRevenue', name: 'Expected Revenue', valueFormatter: formatCurrency, type: 'rightAligned' },
    { key: 'revenue', name: 'Total Revenue',  valueFormatter: formatCurrency, type: 'rightAligned' }
  ]

  return (<AgGridReact
    rowData={rows}>
    {columns.map(col => (<AgGridColumn field={col.key} key={col.key}
      cellRenderer={col.cellRenderer} enableCellTextSelection={true}
      valueFormatter={col.valueFormatter} headerName={col.name}
      resizable={true} sortable={true} type={col.type} comparator={col.comparator} ></AgGridColumn>))}
  </AgGridReact>)

}

export default () => {
  const [filterVals, updateFilterVals] = useState({})
  const [selection, updateSelection] = useState({})
  const [sortValue, updateSortValue] = useState('updated')
  const [sortOrder, updateOrder] = useState('asc')
  const [getReport, { called,loading, data }] = useLazyQuery(QUERY, {
    fetchPolicy: 'network-only'
  })
  const filterData = useQuery(FILTER_QUERY)

  if (called && loading) {
    return <Spinner />
  }

  if (!called) {
    getReport({
      variables: Object.keys(filterVals).reduce((c, k) => {
        if(filterVals[k].length)
          c[k] = filterVals[k]
        return c
      }, {})
    })
  }

  let referralYears = Array(6).fill(true).map((v, i) => {
    const yr = moment().subtract(i, 'years').format("YYYY")
    return {
      id: yr, name: yr
    }
  })

  const filterOnChange = async (kv) => {
    let newVals = {}
    let options = {}
    console.log(`Setting: ${kv.name} => ${kv.value}`)
    if (kv.value && kv.multi) {
      const value = (kv.type == Number || !kv.type)  ? parseInt(kv.value) : kv.value
      let existing = filterVals[kv.name] || []
      if (existing.includes(value))
        existing = existing.filter(v => v !== value)
      else
        existing.push(value)
      let newVals = { ...filterVals, [`${kv.name}`]: existing }
      let options = { ...selection, [`${kv.name}`]: existing }
      updateFilterVals(prv => ({...newVals}))
      updateSelection(prv => ({ ...options }))
    }
    else if (kv.value && kv.value.type === 'radio') {
      const value = parseInt(kv.value.value)
      let newVals = { ...filterVals, [`${kv.name}`]: value }
      let options = { ...selection, [`${kv.name}`]: value }

      const newState = { ...newVals }
      const newOptions = { ...options }
      updateFilterVals(prv => newState)
      updateSelection(prv => newOptions)
    } else if (kv.value) {
      newVals = kv.name === 'referralDate' ? { ...filterVals, [`${kv.name}`]: kv.value.name } : { ...filterVals, [`${kv.name}Id`]: kv.value.id }
      options = { ...selection, [`${kv.name}`]: { name: kv.value.name, id: kv.value.id } }

      const newState = { ...newVals }
      const newOptions = { ...options }

      await updateFilterVals(prv => newState)
      await updateSelection(prv => newOptions)

    } else {
      const { ...state } = filterVals
      const { ...ops } = selection
      console.log(`deleting ${kv.name}`)
      delete state[kv.name]
      delete ops[kv.name]

      const newState = { ...state }
      const newOptions = { ...ops }

      await updateFilterVals(newState)
      await updateSelection(newOptions)
    }

  }

  const clear = () => {
    updateFilterVals({})
    updateSelection({})
  }
  const updateSortOrder = (s) => {
    const defaultSortOrder = 'asc'
    const newSortOrder = 'desc'
    if (s === sortValue) {
      updateOrder(sortOrder === defaultSortOrder ? newSortOrder : defaultSortOrder)
    } else {
      updateOrder(defaultSortOrder)
    }

    updateSortValue(s)
  }

  const tableData = sortByAttribute(data ? data.claims : [], sortValue, sortOrder)

  return (
    <>
      <h1>Open Claims Report</h1>
      <ReportFilters
        updateReportFilters={filterOnChange}
        values={selection}
        referralYears={referralYears}
        reset={clear}
        submit={()=> getReport( {
          variables: Object.keys(filterVals).reduce((c, k) => {
            if(filterVals[k].length)
              c[k] = filterVals[k]
            return c
          }, {})
          , fetchPolicy: 'network-only'
        })}
        {...(filterData.data)}
      />
      {tableData && <div> {tableData.length} rows </div>}
      <Divider />
      <div id='reportTable' className="ag-theme-alpine" style={ { height: "65vh", width: "100%" } }>
        <DataTable rows={tableData}></DataTable>
      </div>
    </>
  )
}
