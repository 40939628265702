import React from 'react'
import { AuthenticationState } from 'react-aad-msal';
// import { useAuth } from '../../hooks/auth'
import { Button } from '../../elements'
import { navigate } from '@reach/router'
import { authProvider } from '../../auth'

export default () => {

    const isLoggedIn = () => authProvider.authenticationState == AuthenticationState.Authenticated
    const authState = {}

    isLoggedIn() && navigate('/')

    return (
        <>
            {isLoggedIn() && <span>Logged in!</span>}
            {!isLoggedIn() && authState.error === null &&
                <div>
                    You are currently logged out. Please log in.
                        <Button onClick={() => navigate('/auth')} text={'Login'} />
                </div>}

                {!isLoggedIn() && authState.error !== null &&
                <div>
                    Error logging in. Please contact an administrator.
                        <Button onClick={() => navigate('/auth')} text={'Login'} />
                </div>}
        </>
    )
}