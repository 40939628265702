// authProvider.js
import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const GRAPH_SCOPES = {
    USER_READ: 'User.Read',
    OPENID: 'openid',
    ID_TOKEN : 'id_token'
}

// Msal Configurations
const config = {
  auth: {
    clientId: 'f2cfa562-ec0c-4492-9cdc-60d60e8ec6de',
    authority: 'https://login.microsoftonline.com/9af628ac-b9dc-4db1-8ad4-81ffd2f86a70',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true
  }
};

// Authentication Parameters
const authenticationParameters = {
  scopes: [
		GRAPH_SCOPES.USER_READ,
	  	GRAPH_SCOPES.OPENID
  ]
}

// Options
const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/login'
}

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options)