import React from 'react'
import { IsWriter, IsAdmin, IsHospital } from '../Auth'
import Dashboard from '../Dashboard'
import AuthorDashboard from '../AuthorDashboard'
import HospitalDashboard from '../HospitalDashboard'

export default () => {
    return (
        <>
            <IsWriter>
                <AuthorDashboard />
            </IsWriter>
            <IsAdmin>
                <Dashboard />
            </IsAdmin>
            <IsHospital>
                <HospitalDashboard />
            </IsHospital>
        </>
    )
}