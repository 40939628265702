import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { orderBy } from 'lodash'

import { Spinner } from '@blueprintjs/core'
import HospitalClaimTable from '../HospitalClaimTable'
import { FormSimpleSelect } from '../../elements/Input'
import moment from 'moment'

const sortByAttribute = (items, attr, order) => {
    return orderBy(items, [(o) => o[attr] && (o[attr].name || o[attr])], [order])
}

const QUERY = gql`
query($statusId: [Int],$referralDate: [String]) {
    hospitals {
      name
    }
    appeal_statuses {
      id
      name
    }
    claims(statusId: $statusId, referralDate: $referralDate) {
        claim
        account
        hospital {
          name
        }
        status {
          name
        }
        dueDate
        updated
        revenue
        expectedRevenue
        referralDate
    }
  }
`

const arrayIze = (vals) => {
    const arrayFlds = ['hospitalId', 'statusId', 'paid', 'invoiced']
    arrayFlds.forEach(f => {
        if (vals[f] !==undefined && vals[f] !== null && !Array.isArray(vals[f]))
            vals[f] = [vals[f]]
    })
    return vals
}

export default (props) => {
    const [sortValue, updateSortValue] = useState('dueDate')
    const [sortOrder, updateOrder] = useState('asc')
    const [filterVals, updateFilterVals] = useState({})
    const [selection, updateSelection] = useState({})
    const { loading, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: arrayIze(filterVals)
    })

    if (loading) {
        return <Spinner />
    }

    const updateSortOrder = (s) => {
        if (s === sortValue) {
            updateOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        } else {
            updateOrder('asc')
        }

        updateSortValue(s)
    }

    const addDefault = (op) => {
        const defaultVal = {
            id: -1,
            name: 'Select...'
        }
        let optionsToReturn = op
        if (!op.find(o => o.id === -1)) {
            optionsToReturn.unshift(defaultVal)
        }

        return optionsToReturn
    }
    const DEFAULT = {
        id: -1,
        name: 'Select...'
    }

    const filterStyle = {
        width: 320
    }

    const filterContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        width: '50%',
        justifyContent: 'space-between',
        marginBottom: '20px'
    }

    const referralYears = [{
        id: -1,
        name: 'Select...'
    }, {
        id: 1,
        name: moment().format("YYYY")
    }, {
        id: 2,
        name: moment().subtract(1, 'years').format("YYYY")
    }, {
        id: 3,
        name: moment().subtract(2, 'years').format("YYYY")
    }, {
        id: 4,
        name: moment().subtract(3, 'years').format("YYYY")
    }, {
        id: 5,
        name: moment().subtract(4, 'years').format("YYYY")
    }, {
        id: 6,
        name: moment().subtract(5, 'years').format("YYYY")
    }]
    const filterOnChange = async (kv) => {
        let newVals = {}
        let options = {}

        if (kv.value) {
            newVals = kv.name === 'referralDate' ? { ...filterVals, [`${kv.name}`]: kv.value.name } : { ...filterVals, [`${kv.name}Id`]: kv.value.id }
            options = { ...selection, [`${kv.name}`]: { name: kv.value.name, id: kv.value.id } }

            const newState = { ...newVals }
            const newOptions = { ...options }

            await updateFilterVals(prv => newState)
            await updateSelection(prv => newOptions)

        } else {
            const { ...state } = filterVals
            const { ...ops } = selection

            delete state[kv.name]
            delete ops[kv.name]

            const newState = { ...state }
            const newOptions = { ...ops }

            await updateFilterVals(newState)
            await updateSelection(newOptions)
        }

    }

    return <>
        <h1>Hospital Claims</h1>
        <div style={filterContainerStyle}>
            <FormSimpleSelect
                label="Referral Year"
                onChange={(e) => filterOnChange({
                    name: 'referralDate',
                    value: e || null
                })}
                items={referralYears || []}
                style={filterStyle}
                value={selection.referralDate || referralYears[0]}
            />
            <FormSimpleSelect
                label="Claim Status"
                onChange={(e) => filterOnChange({
                    name: 'status',
                    value: e || null
                })}
                style={filterStyle}
                items={data.appeal_statuses ? addDefault(data.appeal_statuses) : []}
                value={selection.status || DEFAULT}
                /></div>
        <HospitalClaimTable
            sortValue={sortValue}
            sortOrder={sortOrder}
            updateSortOrder={updateSortOrder}
            items={sortByAttribute(data ? data.claims : [], sortValue, sortOrder)}
        />
    </>
}