import React, {useState} from 'react'
import './../../../index.css'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import NotInvoiced from '../NotInvoiced'
import Invoices from '../Invoices'

const trueFalseValues = [
  {
      name: 'True',
      id: 1
  },
  {
      name: 'False',
      id: 0
  }
]

export default () => {
  const [tabMode, setTabMode] = useState("notInvoiced")


  return (
    <>
      <h1>Process Invoices Report</h1>
      <Tabs id="denialReferral" activeKey={tabMode} onSelect={(k)=>setTabMode(k)}>
        <Tab eventKey="notInvoiced" title="Payments">
          <NotInvoiced trueFalseValues={trueFalseValues} />
        </Tab>
        <Tab eventKey="invoices" title="Invoices">
          <Invoices trueFalseValues={trueFalseValues} />
        </Tab>
      </Tabs>
    </>
  )
}
