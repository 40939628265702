
// from deps
import React, { useCallback, useState } from 'react'
import { Navbar, Overlay, Card, HTMLSelect, FileInput, Menu, MenuItem, Popover, Button } from '@blueprintjs/core'
import { Router, navigate } from '@reach/router'
import { ExcelRenderer } from 'react-excel-renderer';
import ApolloClient from 'apollo-client'
import { ApolloProvider } from '@apollo/react-hooks'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink } from 'apollo-link'
import { onError as onApolloError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ErrorBoundary } from 'react-error-boundary'
import download from 'downloadjs'

// Local
import Logo from './Resources/logo.png';
import DropZone from './elements/DropZone'
import NotFound from './components/NotFound'
import { NavButtonLink, CancelButton, ImportButton } from './elements'
import ClaimAdd from './components/ClaimAdd'
import ClaimEdit from './components/ClaimEdit'
import Reports from './components/Reports/OpenClaimsReport'
import ProcessInvoiceReport from './components/Reports/ProcessInvoiceReport'
import AuthRoute from './components/AuthRoute'
import DenialReferralReport from './components/Reports/DenialReferralReport'
import Login from './components/Login'
import AuthorPayrollReport from './components/Reports/AuthorPayrollReport';
import PerformanceReport from './components/Reports/PerformanceReport';
import CloseoutReport from './components/Reports/CloseoutReport';
import { IsAdmin, IsHospital } from './components/Auth'
import Home from './components/Home';
import AssignHospital from './components/Admin/AssignHospital';
import { authProvider } from './auth'

const containerStyle = {
  marginLeft: "10px"
}
const modalCardStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
}

const buttonStyle = {
  minWidth: "150px",
  margin: "0 15px"
}
const fileListStyle = {
  margin: "10px 15px 0px 15px"
}
const isLocal = window.location.hostname === 'localhost'

const API_URL = process.env.REACT_APP_API_URL

const errorLink = onApolloError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    console.error(networkError)
    // localStorage.setItem(KEY_LOGGED_IN, false)
    // localStorage.setItem(KEY_AUTH, null)
  }
})

const httpLink = createHttpLink({
  uri: isLocal ? '/graphql' : `${API_URL}/graphql`
})

const authLink = setContext(async (_, { headers }) => {
  const {idToken} = await authProvider.getIdToken();
  return {
    headers: {
      ...headers,
      'Authorization': idToken ? `Bearer ${idToken.rawIdToken}` : ''
    }
  }
})

const link = ApolloLink.from([
  errorLink,
  authLink,
  httpLink
])

const cache = new InMemoryCache()

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    query: {
      fetchPolicy: 'network-only'
    }
  }
})

export default () => {
  console.log(authProvider)
  const { login, logout } = authProvider
  const [modal, modalToggle] = useState(false)
  const [fileToUpload, updateFileToUpload] = useState(null)
  const resetModal = () => {
    modalToggle(false)
    updateFileToUpload(null)
  }
  const hospitals = [
    "Brookdale Hospital", "IMC"
  ]
  const submitDocument = (file) => {
    let fileObj = file
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      }
      else {
        console.log(resp.rows)
      }
      resetModal()
    })
  }

  const claimsUrl = `${ !isLocal ? API_URL : '' }/claims/xlsx`
  const exportClaims = async () => {
    const {idToken} = await authProvider.getIdToken();
    fetch(claimsUrl, {
      headers: {
        'Authorization': `Bearer ${idToken.rawIdToken}`,
        'Content-Type': 'application/json',

        'Accept': 'application/json'
      }
    })
      .then(res => res.blob())
      .then(blob => download(blob, 'claims.xlsx'))
  }
  return (
    <ApolloProvider client={client}>
      <div>
        <Navbar>
          <Navbar.Group>
            <Navbar.Heading className="heading">
              <a href="/">
                <img src={Logo} alt="site logo" className="logoStyle" />
              </a>
              <a href="/">Claims Portal </a>
            </Navbar.Heading>
            <Navbar.Divider />
                <IsAdmin>
                  <NavButtonLink to="/claim/add" disabled={window.location.pathname === "/claim/add"} text="Add Claim" />
                  <NavButtonLink disabled onClick={() => modalToggle(true)} text="Import Claim List" />
                  <NavButtonLink onClick={exportClaims} text="Export Claims" />
                </IsAdmin>
                <Popover
                  content={
                    <Menu>
                      <IsAdmin>
                        <MenuItem onClick={() => navigate("/report/open")} text="Open Claims" />
                        <MenuItem onClick={() => navigate("/report/invoice")} text="Process & Invoice" />
                        <MenuItem onClick={() => navigate("/report/author")} text="Process & Authors Payroll" />
                        <MenuItem onClick={() => navigate("/report/closeout")} text="Closeout Report" />
                        <MenuItem onClick={() => navigate("/report/referral")} text="Denial Referral" />
                        <MenuItem onClick={() => navigate("/report/performance")} text="Inpatient & Outpatient Performance" />
                      </IsAdmin>
                      <IsHospital>
                        <MenuItem onClick={() => navigate("/report/performance")} text="Inpatient & Outpatient Performance" />
                      </IsHospital>
                    </Menu>
                  }
                >
                  <Button minimal>Reports</Button>
                </Popover>
                <IsAdmin>
                <Popover
                    content={
                      <Menu>
                        <MenuItem onClick={() => navigate("/admin/assignHospital")} text="Assign Hospital" />
                      </Menu>}
                  >
                  <Button minimal>Admin</Button>
                  </Popover>
                </IsAdmin>
                <NavButtonLink text="Logout" onClick={logout} />
          </Navbar.Group>
        </Navbar>

        <ErrorBoundary FallbackComponent={Error}>
          <div style={containerStyle}>
            <Router>
              <Home path="/" />
              <ClaimAdd path="/claim/add" />
              <ClaimEdit path="/claim/edit/:claim" />
              <Reports path="/report/open" />
              <CloseoutReport path="/report/closeout" />
              <ProcessInvoiceReport path="/report/invoice" />
              <DenialReferralReport path="/report/referral" />
              <AuthorPayrollReport path="/report/author" />
              <PerformanceReport path="/report/performance" />
              <AssignHospital path="/admin/assignHospital" />
              <Login path='/login' onClick={login} />
              <NotFound default />
            </Router>
          </div>
          <Overlay
            canEscapeKey
            isOpen={modal}
            usePortal
          >
            <div>
              <Card style={modalCardStyle}>
                <h3>Import Claim List</h3>
                <div>
                  <FileInput onInputChange={(e) => {
                    updateFileToUpload(e.target.files ? e.target.files[0] : null)
                  }} buttontext="Search for file" style={buttonStyle} text="" />
                  <HTMLSelect
                    style={buttonStyle}
                    name="hospital"
                    label=""
                    autoComplete='off'
                    options={hospitals}
                    width="50%"
                  />
                </div>
                {fileToUpload &&
                  <p style={fileListStyle}>{fileToUpload.name}</p>}
                <DropZone file={fileToUpload} updateFileToUpload={updateFileToUpload} /><div>
                  <CancelButton onClick={() => resetModal()} />
                  <ImportButton onClick={fileToUpload ? () => submitDocument(fileToUpload) : null} />
                </div>
              </Card>
            </div>
          </Overlay>
        </ErrorBoundary>
      </div></ApolloProvider>)
}