import * as Yup from 'yup';
import LookupType from './LookupType';
import NotesType from './NotesType';
var ClaimSchema = Yup.object().shape({
  claim: Yup.string().label('Claim #').required('**Claim # is a required field**'),
  firstName: Yup.string().label('First Name').required('**Patient First Name is a required field**'),
  lastName: Yup.string().label('Last Name').required('**Patient Last Name is a required field**'),
  dob: Yup.string().nullable(),
  phone: Yup.string().nullable(),
  payer: Yup.string().nullable(),
  hospital: Yup.object().shape({
    id: Yup.mixed().required(),
    name: Yup.string().label('Hospital Name').required('**Hospital Name is a required field**')
  }),
  account: Yup.string().nullable(),
  appealDenial: LookupType.nullable(),
  referralDate: Yup.string().nullable(),
  finClass: LookupType.nullable(),
  denialType: LookupType.nullable(),
  careLevel: LookupType.nullable(),
  expectedRevenue: Yup.string().nullable(),
  revenue: Yup.string().nullable().default('0.00'),
  iadLetterDate: Yup.string().nullable(),
  fadLetterDate: Yup.string().nullable(),
  principalDX: Yup.string().nullable(),
  address: Yup.string().nullable(),
  medicalRecord: Yup.string().nullable(),
  admitDOSDate: Yup.string().label('Admit Date').required('**Admit Date is a required field**'),
  dischargeDate: Yup.string().nullable(),
  admitDoctor: Yup.string().nullable(),
  assignedTo: LookupType.nullable(),
  assignedDate: Yup.string().nullable(),
  dueDate: Yup.string().nullable().default(null),
  status: LookupType.nullable(),
  accountType: LookupType.nullable(),
  unitsDenied: Yup.string().nullable(),
  unitsWon: Yup.string().nullable(),
  closeDate: Yup.string().nullable(),
  noAppealType: LookupType.nullable(),
  externalFile: Yup.string().nullable(),
  externalReview: Yup.string().nullable(),
  concurrentFee: Yup.string().nullable(),
  concurrentDate: Yup.string().nullable(),
  paid: Yup.boolean().default(false),
  billedCharges: Yup.string().nullable(),
  paidCADate: Yup.string().nullable(),
  invoicedDate: Yup.string().nullable(),
  paidWriterDate: Yup.string().nullable(),
  invoiced: Yup.boolean().default(false),
  writerPaid: Yup.boolean().default(false),
  cannotBeAppealed: Yup.boolean().default(null).nullable(),
  invoiceNumber: Yup.string().nullable().default(null),
  externalAppFee: Yup.string().nullable(),
  externalAppPaidDate: Yup.string().nullable(),
  externalAppDate: Yup.string().nullable(),
  notes: NotesType.nullable(),
  firstSubmitDate: Yup.string().nullable(),
  secondSubmitDate: Yup.string().nullable(),
  thirdSubmitDate: Yup.string().nullable(),
  firstDecisionDate: Yup.string().nullable(),
  secondDecisionDate: Yup.string().nullable(),
  thirdDecisionDate: Yup.string().nullable(),
  dispositionDate: Yup.date().nullable(),
  dispositionStatus: LookupType.nullable(),
  closingBalance: Yup.string().nullable()
});
export default ClaimSchema;