import React from 'react'
import { Table } from '../../../elements'


const tableStyle = {
  border: 'none',
  marginTop: 10
}
const headerStyle = {
background: 'rgba(191, 204, 214, 0.15)'
}


export default ({data, claims}) => {

    const claimNumber = claims.length
    const claimsNoAppeal = claims.filter(c => c.cannotBeAppealed === 1).length
    const totalCashCollected = claims.reduce( function(tot, record) {
      return tot + parseFloat(record.revenue) || 0},0);
    const resolvedClaims = claims.filter(c => c.closeDate != null).length
    const appealedClaims = claims.filter(c => c.appealSubmitDate != null).length
    const deniedNoRightsClaims = claims.filter(c => c.appealDenial && c.appealDenial.id === 1).length
    const deniedNoReleaseClaims = claims.filter(c => c.appealDenial && c.appealDenial.id === 3).length
    const deniedNoRightsLoss = claims.filter(c => c.appealDenial && c.appealDenial.id === 1).reduce( function(tot, record) {
      return tot + parseFloat(record.expectedRevenue) || 0},0);
    const deniedNoReleaseLoss = claims.filter(c => c.appealDenial && c.appealDenial.id === 3).reduce( function(tot, record) {
      return tot + parseFloat(record.expectedRevenue) || 0},0);
    const claimsWon = claims.filter(c => c.status && (c.status.id === 9 || c.status.id === 10 || c.status.id === 11)).length
    const claimsLost = claims.filter(c =>  c.status && (c.status.id === 12 || c.status.id === 13 || c.status.id === 14)).length
    const claimsPending = claims.filter(c => c.closeDate === null).length
    const claimsWonNotPaid = claims.filter(c => c.status && (c.status.id === 9 || c.status.id === 10 || c.status.id === 11) && (c.paid === 0 || c.paid === null)).length
    const winPercent = claimsWon && resolvedClaims ? 100*(claimsWon/resolvedClaims) : 0
    const externalResolvedClaims = claims.filter(c => c.closeDate != null && c.externalAppDate != null).length
    const externalWinPercent = claimsWon && externalResolvedClaims ? 100*(claimsWon/externalResolvedClaims) : 0
    return (
        <>
        <div id='reportTable'>
            <Table style={tableStyle}>
              <tbody>
                <tr>
                  <th style={headerStyle} key={`report-header-denials-received`}>Total Inpatient Denials Received:</th>
                  <td>{claimNumber}</td>
                </tr>
                <tr>
                  <th key={`report-header-denials-no-appeal`}>Total Inpatient Denials That Could Not Be Appealed:</th>
                  <td>{claimsNoAppeal}</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-denials-appeal`}>Total Inpatient Denials Appealed:</th>
                  <td>{appealedClaims}</td>
                </tr>
                <tr>
                  <th key={`report-header-denials-appeal-won`}>Total Inpatient Appeals Won:</th>
                  <td>{claimsWon}</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-no-appeal-rights`}>Total Appeals Lost/Exhausted Appeal Rights:</th>
                  <td>{claimsLost}</td>
                </tr>
                <tr>
                  <th key={`report-header-won-appeal`}>Total Inpatient Appeals Pending/Open:</th>
                  <td>{claimsPending}</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-won-appeal`}>Total Resolved Inpatient Appeals:</th>
                  <td>{resolvedClaims}</td>
                </tr>
                <tr>
                  <th key={`report-header-no-external-appeal-rights`}>Total Number of Inpatient Denials that could not be appealed externally due to lack of external appeal rights in the managed care contract:</th>
                  <td>{deniedNoRightsClaims}</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-no-external-appeal-release`}>Total Number of Inpatient Denials that could not be appealed externally due to lack of external appeal release form:</th>
                  <td>{deniedNoReleaseClaims}</td>
                </tr>
                <tr>
                  <th key={`report-header-final-appeal-win`}>Final Inpatient Appeal Winning Percentage of Resolved Denials:</th>
                  <td>{winPercent} %</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-final-appeal-win-external`}>Final Inpatient Appeal Winning Percentage of Resolved Denials that could be Appealed Externally:</th>
                  <td>{externalWinPercent} %</td>
                </tr>
                <tr>
                  <th key={`report-header-managed-care`}>Total Revenue Loss Due to Lack of External Appeal Rights in Managed Care Contract:</th>
                  <td>$ {deniedNoRightsLoss.toFixed(2)}</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-no-valid-release`}>Total Revenue Loss Due to Not Obtaining a Valid/Signed External Appeal Release Form:</th>
                  <td>$ {deniedNoReleaseLoss.toFixed(2)}</td>
                </tr>
                <tr>
                  <th key={`report-header-won-not-paid`}>Total Number of Winning Inpatient Appeals rights in the managed care contract Pending Payment (i.e. Appeal Won but Claim hasn't been paid yet)</th>
                  <td>{claimsWonNotPaid}</td>
                </tr>
                <tr>
                  <th style={headerStyle} key={`report-header-total-cash`}>Total Cash Collections from Overturned Inpatient Denials</th>
                  <td>$ {totalCashCollected.toFixed(2)}</td>
                </tr></tbody>
            </Table>
          </div>
        </>)}