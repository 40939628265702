import React from 'react'
import { defaultProps, withProps } from 'recompose'
import { Link } from '@reach/router'

import { Button as CoreButton, Intent } from '@blueprintjs/core'

export const Button = defaultProps({
    style: {
        marginLeft: 10,
        whiteSpace: 'nowrap'
    }
})(CoreButton)


export const ButtonLink = ({ disabled, to="", ...props }) => {
    if (disabled) {
        return <Button disabled {...props} />
    }

    return (
            <Button {...props} />
    )
}

export const NavButton = (props) => {
  return (
    <CoreButton minimal {...props} />
  )
}

export const NavButtonLink = ({ to="/", disabled, ...props }) => {
  if (disabled) {
    return <Button minimal disabled {...props} />
}
return (
    <Link to={to}>
      <Button minimal {...props} />
    </Link>
  )
}

export const GoBackButton = withProps({
    intent: Intent.PRIMARY,
    text: 'Go Back',
    type: 'reset',
    to: '/'
  })(Button)

  export const SaveButton = withProps({
    intent: Intent.PRIMARY,
    text: 'Save',
    type: 'submit',
    minimal: false,
    to: '/'
  })(Button)

  export const SubmitButton = withProps({
    intent: Intent.PRIMARY,
    text: 'Submit',
    type: 'submit',
    minimal: false,
    to: '/'
  })(Button)

  export const ImportButton = withProps({
    intent: Intent.PRIMARY,
    text: 'Import',
    type: 'submit',
    minimal: false,
    to: '/'
  })(Button)
  
  export const CancelButton = withProps({
    intent: Intent.DANGER,
    text: 'Cancel',
    type: 'reset',
    minimal: false,
    to: '/'
  })(NavButtonLink)

  export const RequestButton = withProps({
    intent: Intent.SUCCESS,
    text: 'Request',
    type: 'submit',
    minimal: false
  })(Button)