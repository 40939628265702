import React from 'react'
import './../../../index.css'
import AuthorPayrollSummary from '../AuthorPayrollSummary'
import AuthorPayrollPayment from '../AuthorPayrollPayment'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';


export default () => {

    return (
        <>
            <h1>Process Author Payroll Report</h1>
            <Tabs defaultActiveKey="payment" id="authorpayroll">
                <Tab eventKey="payment" title="Payment">
                    <AuthorPayrollPayment />
                </Tab>
                <Tab eventKey="summary" title="Summary">
                    <AuthorPayrollSummary/>
                </Tab>
            </Tabs>

        </>
    )
}
