import React from 'react'
import { navigate } from '@reach/router'
import { Formik } from 'formik'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { HospitalSchema } from '@ca/validation'
import HospitalForm from '../HospitalForm'
import { HTMLTable } from '@blueprintjs/core'

const FRAGMENT = gql`
  fragment HospitalFragment on AssignedHospital {
      hospital {
          id
          name
      }
      account {
          id
          name
      }
  }`

const QUERY = gql`
  query {
    hospitals {
      id
      name
    }
    unassigneds {
      name
      id
    }
    users{
      name
      id
      hospitalId
      userTypeId
    }
    userTypes {
        id
        name
    }

  }
`
const border = {
    border: '1px solid black',
    borderCollapse: 'initial',
    marginBottom: '25px'
}
const MUTATION = gql`
mutation UpdateHospital($assignHospital: AssignHospital!) {
  updateHospital(assignHospital: $assignHospital) {
    ...HospitalFragment
  }
}
${FRAGMENT}`

export default () => {
    const [updateHospital] = useMutation(MUTATION)
    const { loading, data } = useQuery(QUERY, {
        fetchPolicy: 'network-only'
    })
    if (loading) {
        return (<div>Loading...</div>)
    } else {
        const onSubmit = async (props) => {
            const model = HospitalSchema.cast(props, { stripUnknown: true })
            await updateHospital({
                variables: {
                    assignHospital: model
                }
            })
            navigate('/')
        }
        console.log(data.userTypes)

        return (
            <>
                <h1>Assign Hospital Account</h1>
                <Formik
                    initialValues={{ hospital: {}, account: {} }}
                    onSubmit={onSubmit}
                    validationSchema={HospitalSchema}
                    render={(props) => {
                        return <HospitalForm
                            errors={props.errors}
                            unassigneds={data && data.unassigneds ? data.unassigneds : []}
                            hospitals={data && data.hospitals ? data.hospitals : []}
                            {...props} />
                    }}
                />
                <h4>Current Assignments</h4>
                <HTMLTable bordered className="bp3-html-table-condensed" style={border}>
                    <thead>
                        <tr><th></th></tr>
                        <tr>
                            <th>Role</th>
                            <th>Account Name</th>
                            <th>Hospital</th>
                        </tr>
                    </thead>
                    <tbody>{data.users.map(user => {
                        return (
                            <tr key={user.name}>
                                <td>{user.userTypeId ? data.userTypes.find(u => u.id === user.userTypeId).name : "No User Role"}</td>
                                <td>{user.name}</td>
                                <td>{user.hospitalId ? data.hospitals.find(h => h.id === user.hospitalId).name : 'Unassigned'}</td></tr>
                        )
                    })}
                    </tbody></HTMLTable></>
        )
    }
}