import React from 'react';
import { Router } from '@reach/router'
import { AuthenticationState } from 'react-aad-msal';
import { AzureAD } from 'react-aad-msal';
import { authProvider } from './auth';

import AuthApp from './AuthApp'
import AuthCallback from './components/AuthCallback';



export default () => {
  return (
    <>
      <AzureAD provider={authProvider} forceLogin={true}>
      {
        ({login, logout, authenticationState, error, accountInfo}) => {
          switch (authenticationState) {
            case AuthenticationState.Authenticated:
              return <AuthApp />;
            case AuthenticationState.Unauthenticated:
              return (
                <div>
                  {error && <p><span>An error occurred during authentication, please try again!</span></p>}
                  <p>
                    <button onClick={login}>Login</button>
                  </p>
                </div>
              );
            case AuthenticationState.InProgress:
              return (<p>Authenticating...</p>);
          }
        }
      }
      </AzureAD>
    </>
  )
}
