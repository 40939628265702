import React, { useCallback, useState } from 'react'
import { authProvider } from '../../auth'

const writer = "Writer"
const admin = "Admin"
const hospital = "Hospital"

export const checkRole = (role) => ({ ...props }) => {
	const { account } = authProvider
	const isRole = account && account.idToken && account.idToken.roles && account.idToken.roles.includes(role)

    return isRole ? props.children : null
}

export const IsWriter = checkRole(writer)
export const IsAdmin = checkRole(admin)
export const IsHospital = checkRole(hospital)

