import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { orderBy } from 'lodash'
import { Spinner } from '@blueprintjs/core'
import AuthorClaimTable from '../AuthorClaimTable'
import { RequestButton } from '../../elements'
import { authProvider } from '../../auth'


const sortByAttribute = (items, attr, order) => {
    if (attr === 'claim') {
        items.forEach(function (part, index) {
            part.claim = parseInt(part.claim)
        })
    }
    return orderBy(items, [(o) => o[attr] && (o[attr].name || o[attr])], [order])
}

const FRAGMENT = gql`
fragment AuthorDetailFragment on ClaimDetails {
    claim
        hospital {
            name
        }
        assignedTo {
        name
      }
        account
        dueDate
        lastName
        firstName
        dischargeDate
        admitDOSDate
        status {
            id
        }
        appealSubmitDate
}
`

const QUERY = gql`
    ${FRAGMENT}
    query {
        claims {
            ...AuthorDetailFragment
        }
    }
`
const MUTATION_FRAGMENT = gql`
fragment ClaimAssignmentFragment on ClaimDetails {
    claim
    firstName
    lastName
    hospital {
        name
    }
    admitDOSDate
    assignedTo {
        id
    }
}`

const SUBMITTED_FRAGMENT = gql`
fragment ClaimSubmittedFragment on ClaimDetails {
    claim
}`

const MUTATION = gql`
  mutation ClaimAssignment($id: ID) {
    claimAssignment(id: $id) {
        ...ClaimAssignmentFragment
    }
  }${MUTATION_FRAGMENT}`

const SUBMIT_MUTATION = gql`
mutation SubmitClaim($id: ID!) {
    submitClaim(id: $id) {
      ...ClaimSubmittedFragment
  }
}${SUBMITTED_FRAGMENT}`


export default (props) => {
    const { account } = authProvider
    const email = account.userName
    const user = account.name
    const [claim, updateClaim] = useState(null)
    const [submitClaim] = useMutation(SUBMIT_MUTATION, {
        onCompleted({submitClaim}) {
            refetch()
        }
    })
    const { loading, data, refetch } = useQuery(QUERY, {
        fetchPolicy: 'network-only'
    })
    const [claimAssignment] = useMutation(MUTATION, {
        onCompleted({ claimAssignment }) {
        const hospital = claimAssignment.hospital && claimAssignment.hospital.name
        const admitDate = claimAssignment.admitDOSDate
          fetch('https://prod-22.westus.logic.azure.com:443/workflows/2fef1fb397b94ff7bbe32d1420d4a78b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=T0Lkn7WBpERDlMrcGJzzxPb0VtdctpyXGX4oghVO5JM', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              claim: claimAssignment.claim,
              assignedTo: email,
              folderName: `${claimAssignment.lastName}, ${claimAssignment.firstName} - ${admitDate} - ${hospital}`
            })})
            updateClaim(claimAssignment.claim)
            refetch()
        }
    })

    const submitAppealClaim = async (props) => {
        submitClaim({
            variables: {
              id: props
            }
          })
    }

    const [sortValue, updateSortValue] = useState('dueDate')
    const [sortOrder, updateOrder] = useState('asc')
    const updateSortOrder = (s) => {
        if (s === sortValue) {
            updateOrder(sortOrder === 'asc' ? 'desc' : 'asc')
        } else {
            updateOrder('asc')
        }

        updateSortValue(s)
    }

    if (loading) {
        return <Spinner />
    }

    return <>
        <h1>{user}'s Claims</h1>
        <AuthorClaimTable
            sortValue={sortValue}
            sortOrder={sortOrder}
            updateSortOrder={updateSortOrder}
            submitClaim={submitAppealClaim}
            items={sortByAttribute(data ? data.claims : [], sortValue, sortOrder)}
        />

        <p style={{ "marginTop": "20px" }}>
            Request new claim assignment
        <RequestButton onClick={claimAssignment} />
        </p>
        {claim === '0' && <p style={{ color: 'red' }}>No available claim assignments</p>}
    </>
}