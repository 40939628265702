import React from 'react'
import { FormSimpleSelect, FormMultiSelect } from '../../../elements/Input'
import { Button } from '../../../elements'
import { Radio, RadioGroup } from '@blueprintjs/core'

const filterContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    width: '90%',
}

const headerStyle = {
    display: 'flex',
    flexDirection: 'row'
}

const filterStyle = {
    width: 250,
    marginRight: 15
}

const buttonStyle = {
    height: 30,
    marginTop: 10,
    marginLeft: 20
}

const submitButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#9bc493'
}


const radioButtonStyle = {
    marginLeft: 20
}
const addDefault = (op, defaultVar=-1 ) => {
    const defaultVal = {
        id: defaultVar,
        name: 'Select...'
    }
    let optionsToReturn = op
    if (!op.find(o => o.id === -1)) {
        optionsToReturn.unshift(defaultVal)
    }

    return optionsToReturn
}

const trueFalseValues = [
    {
        name: 'Paid',
        id: 1
    },
    {
        name: 'Unpaid',
        id: 2
    }
]

const openClosedValues = [
    {
        name: 'Open Only',
        id : 'open'
    },
    {
        name: 'Closed Only',
        id : 'closed'
    }
]

const subStatusValues = [
    {
        name: "1st Level Submit",
        id: "firstSubmitDate"
    },
    {
        name: "2nd Level Submit",
        id: "secondSubmitDate"
    },
    {
        name: "3rd Level Submit",
        id: "thirdSubmitDate"
    },
    {
        name: "1st Decision",
        id: "firstDecisionDate"
    },
    {
        name: "2nd Decision",
        id: "secondDecisionDate"
    },
    {
        name: "3rd Decision",
        id: "thirdDecisionDate"
    },
    {
        name: "External App Submit",
        id: "externalAppDate"
    }
]

export default ({ updateReportFilters, values, data, reset, submit, referralYears, ...props }) => {
    const DEFAULT = {
        id: -1,
        name: 'Select...'
    }
    return (
        <>
            <div style={headerStyle}>
                <h3>Select filters:</h3>
                <Button onClick={submit} style={submitButtonStyle}>Submit</Button>
                <Button onClick={reset} style={buttonStyle}>Clear</Button>
            </div>
            <div style={filterContainerStyle}>
                <div style={filterStyle}>
                    <FormMultiSelect
                        label="Payer"
                        onChange={(item) => {
                            updateReportFilters({
                                name: 'payer',
                                multi: true,
                                existing : values.payer,
                                value: item,
                                type: String
                            })
                        }
                        }
                        items={props.payers}
                        value={values.payer || []}
                    />
                    <FormMultiSelect
                        label="Financial Class"
                        onChange={(item) => updateReportFilters({
                            name: 'finClass',
                            multi: true,
                            existing : values.finClass,
                            value: item.id
                        })}
                        items={props.finClasses}
                        value={values.finClass || []}
                    />
                </div>
                <div style={filterStyle}>
                    <FormMultiSelect
                        label="Hospital"
                        onChange={(item) => updateReportFilters({
                            name: 'hospital',
                            multi: true,
                            existing : values.hospital,
                            value: item.id
                        })}
                        items={props.hospitals}
                        value={values.hospital || []}
                    />
                    <FormMultiSelect
                        label="Account Type"
                        onChange={(item) => updateReportFilters({
                            name: 'accountType',
                            multi: true,
                            existing : values.accountType,
                            value: item.id
                        })}
                        items={props.accountTypes}
                        value={values.accountType || []}
                    />
                </div>
                <div style={filterStyle}>
                    <FormMultiSelect
                        label="Claim Status"
                        onChange={(item) => updateReportFilters({
                            name: 'status',
                            multi: true,
                            existing : values.appealDenial,
                            value: item.id
                        })}
                        items={props.appeal_statuses}
                        value={values.status || []}
                    />
                    <FormMultiSelect
                        label="Inability to Appeal Den Ext"
                        onChange={(item) => updateReportFilters({
                            name: 'appealDenial',
                            multi: true,
                            existing : values.appealDenial,
                            value: item.id
                        })}
                        items={props.appealDenials}
                        value={values.appealDenial || []}
                    />
                </div>

                <div style={filterStyle}>

                    {/* <FormSimpleSelect
                        label="Referral Year"
                        onChange={(e) => updateReportFilters({
                            name: 'referralDate',
                            value: e || null
                        })}
                        items={referralYears ? addDefault(referralYears) : []}
                        value={values.referralDate || DEFAULT}
                    /> */}
                    <FormMultiSelect
                        label="Referral Year"
                        onChange={(item) => updateReportFilters({
                            name: 'referralDate',
                            multi: true,
                            type : String,
                            existing : values.referralDate,
                            value: item.id
                        })}
                        items={referralYears}
                        value={values.referralDate || []}
                    />
                    <FormMultiSelect
                        label="Denial Type"
                        onChange={(item) => updateReportFilters({
                            name: 'denialType',
                            multi: true,
                            existing : values.denialType,
                            value: item.id
                        })}
                        items={props.denialTypes || []}
                        value={values.denialType || []}
                    />
                </div>
                <div style={filterStyle}>
                    <FormMultiSelect
                        label="Open/Closed"
                        onChange={(item) => updateReportFilters({
                            name: 'openClosed',
                            multi: true,
                            type: String,
                            existing : values.openClosed,
                            value: item.id
                        })}
                        items={openClosedValues}
                        value={values.openClosed || []}
                    />
                    <FormMultiSelect
                        label="Assigned To"
                        onChange={(item) => updateReportFilters({
                            name: 'assignedTo',
                            multi: true,
                            existing: values.assignedTo,
                            value: item.id
                        })}
                        idField="id"
                        items={props.users}
                        value={values.assignedTo}
                    />
                </div>
                <div style={filterStyle}>
                    <FormMultiSelect
                        label="Revenue Collected"
                        onChange={(item) => updateReportFilters({
                            name: 'revenue',
                            multi: true,
                            existing: values.revenue,
                            value: item.id
                        })}
                        idField="id"
                        items={trueFalseValues}
                        value={values.revenue}
                    />
                    <FormMultiSelect
                        label="Submission Status"
                        onChange={(item) => updateReportFilters({
                            name: 'submissionStatus',
                            multi: true,
                            existing: values.submissionStatus,
                            type: String,
                            value: item.id
                        })}
                        idField="id"
                        items={subStatusValues}
                        value={values.submissionStatus}
                    />
                </div>
                <div style={filterStyle}>
                    <FormMultiSelect
                        label="Care Level"
                        onChange={(item) => updateReportFilters({
                            name: 'careLevel',
                            multi: true,
                            existing: values.careLevel,
                            value: item.id
                        })}
                        idField="id"
                        items={props.careLevels}
                        value={values.careLevel || []}
                    />

                </div>
                {/* <label style={radioButtonStyle}>Revenue Collected<RadioGroup
                    selectedValue={values.revenue === 0 || values.revenue === 1 ? values.revenue : -1}
                    onChange={(e) => updateReportFilters({
                        name: 'revenue',
                        value: e.target || null
                    })} >
                    {trueFalseValues.map(t => { return <Radio key={t.id} label={t.name} value={t.id} /> })}
                </RadioGroup></label> */}
            </div>
        </>
    )
}