import React from 'react'
import { Intent, NonIdealState } from '@blueprintjs/core'

import { ButtonLink } from '../../elements'

export default () => (
  <NonIdealState
    icon="search"
    title="Page Not Found"
  >
    <ButtonLink 
      large
      to="/"
      intent={Intent.PRIMARY}
      text="Go to Claim List"
    />
  </NonIdealState>
)