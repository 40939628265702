import React from 'react'
import { Form } from '../../elements/Form'
import { Card } from '@blueprintjs/core'
import { SaveButton, CancelButton, Table, Button } from '../../elements'
import { FormikSimpleSelect, FormikInput, FormikPhoneMaskedInput, FormikEditableText, FormikCurrencyInput } from '../../elements/Formik'
import { FieldArray } from 'formik'
import moment from 'moment'

export default ({
    buttonText,
    statuses,
    disposition_statuses,
    hospitals,
    assigned,
    finClasses,
    denialTypes,
    careLevels,
    accountTypes,
    noAppealTypes,
    appealDenials,
    existingClaim = false,
    values = {},
    errors,
    touched,
    removeClaim,
    ...props
}) => {

    const updateAssignedDate = (item, setter) => {
        debugger;
        if(!values.assignedDate)
            setter('assignedDate', moment().format('YYYY-MM-DD'))
     }
    return (
        <Form >
            <Card>
                <FormikInput
                    name="account"
                    id="account"
                    label="Patient Account #"
                />
                <FormikInput
                    name="claim"
                    id='claim'
                    label="Claim #"
                />
                <FormikSimpleSelect
                    name="status"
                    id="status"
                    label="Status"
                    nullable
                    items={statuses}
                />
                <FormikInput
                    name="firstSubmitDate"
                    id="firstSubmitDate"
                    label="1st Level Submit Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    disabled={!existingClaim}
                />
                <FormikInput
                    name="firstDecisionDate"
                    id="firstDecisionDate"
                    label="1st Decision Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    disabled={!existingClaim}
                />
                <FormikInput
                    name="secondSubmitDate"
                    id="secondSubmitDate"
                    label="2nd Level Submit Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    disabled={!existingClaim}
                />
                <FormikInput
                    name="secondDecisionDate"
                    id="secondDecisionDate"
                    label="2nd Decision Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    disabled={!existingClaim}
                />
                <FormikInput
                    name="thirdSubmitDate"
                    id="thirdSubmitDate"
                    label="External Level Submit Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    disabled={!existingClaim}
                />
                <FormikInput
                    name="thirdDecisionDate"
                    id="thirdDecisionDate"
                    label="External Decision Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    disabled={!existingClaim}
                />
                <FormikInput
                    name="dueDate"
                    id="dueDate"
                    label="Due Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikSimpleSelect
                    name="hospital"
                    id="hospital"
                    label="Hospital Name"
                    items={hospitals}
                    nullable
                />
                <FormikInput
                    name="referralDate"
                    id="referralDate"
                    type="date"
                    label="Referral to CA Date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="medicalRecord"
                    id="medicalRecord"
                    label="Medical Record Number"
                />
                <FormikInput
                    name="iadLetterDate"
                    id="iadLetterDate"
                    type="date"
                    label="IAD Letter Date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="fadLetterDate"
                    id="fadLetterDate"
                    type="date"
                    label="FAD Letter Date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikSimpleSelect
                    name="accountType"
                    id="accountType"
                    label="Account Type"
                    items={accountTypes}
                    nullable
                />
                <FormikSimpleSelect
                    name="careLevel"
                    id="careLevel"
                    label="Level of Care"
                    items={careLevels}
                    nullable
                />
                <FormikInput
                    name="admitDOSDate"
                    id="admitDOSDate"
                    label="Admit Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="dischargeDate"
                    id="dischargeDate"
                    label="Discharge"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="principalDX"
                    id="principalDX"
                    label="Principal DX"
                />
                <FormikInput
                    name="payer"
                    id="payer"
                    label="Payer"
                />
                <FormikSimpleSelect
                    name="finClass"
                    id="finClass"
                    label="Financial Class"
                    items={finClasses}
                    nullable
                />
                <FormikSimpleSelect
                    name="denialType"
                    id="denialType"
                    label="Denial Type"
                    items={denialTypes}
                    nullable
                />
                <FormikInput
                    name="admitDoctor"
                    id="admitDoctor"
                    label="Admitting Doctor"
                />
                <FormikSimpleSelect
                    name="assignedTo"
                    id="assignedTo"
                    label="Assigned Writer"
                    type='text'
                    items={assigned}
                    nullable
                    onChange={updateAssignedDate}
                />
                <FormikInput
                    name="assignedDate"
                    id="assignedDate"
                    label="Assigned Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                    value={values.assignedTo && !values.assignedDate ? new Date() : values.assignedDate}
                />
                <FormikCurrencyInput
                    name="expectedRevenue"
                    id="expectedRevenue"
                    label="Expected Reimbursement"
                    placeholder="$0.00"
                    />
                <FormikCurrencyInput
                    name="revenue"
                    id="revenue"
                    label="Revenue Collected"
                    placeholder="$0.00"
                />
                <FormikInput
                    name="unitsDenied"
                    id="unitsDenied"
                    label="Units denied upon referral"
                />
                <FormikInput
                    name="unitsWon"
                    id="unitsWon"
                    label="Units won due to appeal win"
                />
                <FormikSimpleSelect
                    name="appealDenial"
                    id="appealDenial"
                    label="Inability to Appeal Denial Externally"
                    items={appealDenials}
                    nullable
                />
                <FormikSimpleSelect
                    name="noAppealType"
                    id="noAppealType"
                    label="No Appeal"
                    items={noAppealTypes}
                    nullable
                />
                <FormikInput
                    name="externalFile"
                    id="externalFile"
                    label="External Appeal File #"
                />
                <FormikInput
                    name="externalReview"
                    id="externalReview"
                    label="External Review Agency"
                />
                <FormikCurrencyInput
                    name="concurrentFee"
                    id="concurrentFee"
                    label="Concurrent Fee"
                    placeholder="$0.00"
                />
                <FormikInput
                    name="concurrentDate"
                    id="concurrentDate"
                    label="Concurrent Fee Paid Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikCurrencyInput
                    name="externalAppFee"
                    id="externalAppFee"
                    label="External App Fee"
                    placeholder="$0.00"
                />
                <FormikInput
                    name="externalAppPaidDate"
                    id="externalAppPaidDate"
                    label="External App Fee Paid Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                /><FormikInput
                    name="externalAppDate"
                    id="externalAppDate"
                    label="External App Submission Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="closeDate"
                    id="closeDate"
                    label="Appeal Closed Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikCurrencyInput
                    name="billedCharges"
                    id="billedCharges"
                    label="Billed Charges"
                    placeholder="$0.00"
                />
                <FormikInput
                    name="invoicedDate"
                    id="invoicedDate"
                    label="Invoiced Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="invoiceNumber"
                    id="invoiceNumber"
                    label="Invoice Number"
                />
                <FormikInput
                    name="paidCADate"
                    id="paidCADate"
                    label="Claim Paid Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikInput
                    name="paidWriterDate"
                    id="paidWriterDate"
                    label="Win Fee Paid Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <h3>Account Disposition</h3>
                <FormikInput
                    name="dispositionDate"
                    id="dispositionDate"
                    label="Disposition Date"
                    type="date"
                    placeholder="MM/DD/YYYY"
                />
                <FormikSimpleSelect
                    name="dispositionStatus"
                    id="dispositionStatus"
                    label="Disposition Status"
                    items={disposition_statuses}
                    nullable
                />
                <FormikCurrencyInput
                    name="closingBalance"
                    id="closingBalance"
                    label="Closing Account Balance"
                    placeholder="$0.00"
                />
                <h2>Patient Information</h2>
                <FormikInput
                    noValidate
                    name="firstName"
                    id="firstName"
                    label="First Name"
                    autoComplete='off'
                />
                <FormikInput
                    name="lastName"
                    id="lastName"
                    label="Last Name"
                    autoComplete='off'
                />
                <FormikInput
                    name="dob"
                    id="dob"
                    label="DOB"
                    type="date"
                    autoComplete='off'
                />
                <FormikInput
                    name="address"
                    id="address"
                    label="Address"
                />
                <FormikPhoneMaskedInput
                    name="phone"
                    id="phone"
                    label="Phone Number"
                    autoComplete='off'
                />
                <h5>Notes History</h5>
                <FieldArray
                    name="notes"
                    render={({ push }) => {
                        return (<div>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {values.notes && values.notes.map((h, i) => {
                                        return (<tr key={i}>
                                            <td>{new Date(h.date).toLocaleDateString()}</td>
                                            <td>
                                                <FormikEditableText
                                                    name={`notes[${i}].notes`}
                                                    label="Notes"
                                                    placeholder="Notes"
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                            <Button
                                icon='add'
                                text='Add New'
                                type='button'
                                onClick={() => push({ date: new Date(), notes: '' })}
                            />
                        </div>)
                    }}
                />
            </Card>
            {errors.firstName && <div className='errorStyle'>{errors.firstName}</div>}
            {errors.lastName && <div className='errorStyle'>{errors.lastName}</div>}
            {errors.admitDOSDate && <div className='errorStyle'>{errors.admitDOSDate}</div>}
            {errors.hospital && <div className='errorStyle'>{errors.hospital.name}</div>}
            <div className="buttonSetStyle">
                {existingClaim && <Button text='Delete' type='button' onClick={() => removeClaim(values.claim)} />}<CancelButton to="/" /><SaveButton type="submit" to="/" text={buttonText} /></div>
        </Form>
    )
}
