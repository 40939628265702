import React, { useState } from 'react'
import { Table } from '../../../elements'
import { Formik, FieldArray, Form, Field } from 'formik'
import { Divider, RadioGroup, Radio, Spinner } from '@blueprintjs/core'
import { FormSimpleSelect } from '../../../elements/Input'
import { SaveButton, ButtonLink } from '../../../elements'
import './../../../index.css'
import { FormikInput } from '../../../elements/Formik'
import { ClaimSchema_ } from '@ca/validation'
import { gql } from 'apollo-boost'
import { useQuery, useMutation } from '@apollo/react-hooks'

const formatDollarAmt = (amt = 0) => (!isNaN(amt) ? +amt : 0).toLocaleString('en-US', { style: 'currency', currency: 'USD' })

const FRAGMENT = gql`
  fragment InvoicedFragment on ClaimDetails {
    claim
    status {
        name
        id
      }
    paid
    paidCADate
    invoiced
    invoicedDate
    invoiceNumber
    account
    referralDate
    medicalRecord
    firstName
    lastName
    payer
  }`

const QUERY = gql`
  query($hospitalId: [Int], $statusId: [Int], $paid: [Int], $invoiced: [Int], $openClosed: [String], $revenue: [Int]) {
    hospitals {
      id
      name
    }
    appeal_statuses {
      id
      name
    }
    claims(hospitalId: $hospitalId, statusId: $statusId, paid: $paid, invoiced: $invoiced, openClosed: $openClosed, revenue: $revenue) {
      claim
      payer
      firstName
      lastName
      hospital {
        id
        name
      }
      account
      appealDenial {
        id
        name
      }
      status {
        name
        id
      }
      invoicedDate
      invoiceNumber
      admitDOSDate
      dischargeDate
      billedCharges
      revenue
      paid
      paidCADate
      invoiced
    }
  }
`
const MUTATION = gql`
  mutation UpdatePaidInvoicedClaim($id: ID!, $claim_details: ClaimInput!) {
    updatePaidInvoicedClaim(id: $id, claim_details: $claim_details) {
      ...InvoicedFragment
    }
  }
  ${FRAGMENT}`

const GROUP_MUTATION = gql`
    mutation updatePaidInvoicedBatch($invoicedDate: String!
                $invoiceNumber: String!,
                $updates: [InvoicePaidInput]!){
                    updatePaidInvoicedBatch(
                        invoicedDate: $invoicedDate,
                        invoiceNumber: $invoiceNumber,
                        updates: $updates
                    )
    }
`

const arrayIze = (vals) => {
    const arrayFlds = ['hospitalId', 'statusId', 'paid', 'invoiced','openClosed', 'revenue']
    arrayFlds.forEach(f => {
        if (vals[f] !==undefined && vals[f] !== null && !Array.isArray(vals[f]))
            vals[f] = [vals[f]]
    })
    return vals
}

export default ({ trueFalseValues }) => {
    const [filterVals, updateFilterVals] = useState({ invoiced: 0, openClosed: 'closed', revenue: 1  })
    const [selection, updateSelection] = useState({})
    const [invoiced, updateInvoiced] = useState({})

    const { loading, data, refetch } = useQuery(QUERY, {
        fetchPolicy: 'network-only',
        variables: arrayIze(filterVals),
        notifyOnNetworkStatusChange : true,
        onCompleted: (qdata) => {
            const claimsObj = qdata.claims.reduce((curr, claim) => {
                curr[claim.account] = { selected : false, paidCADate : claim.paidCADate || null , claim }
                return curr
            }, {})
            updateInvoiced(claimsObj)
        }
    })

    const [updatePaidInvoicedClaim] = useMutation(MUTATION, {
    })

    const [updatePaidClaims] = useMutation(GROUP_MUTATION, {
    })

    if (loading || !data) {
        return <Spinner />
    }

    const onSubmit = async (props) => {
        const invoiceClaims = Object.keys(invoiced).reduce((c, i) => {
            if (invoiced[i].selected)
                c.push({ id: i , ...invoiced[i] })
            return c
        }, [])

        const { masterInvoicedDate, masterInvoiceNumber } = props
        const toUpdate = invoiceClaims.map( ({ id , paidCADate }) => ({ id, paidCADate, invoiced: 1 })        )

        await updatePaidClaims({
            variables: {
                invoicedDate: masterInvoicedDate,
                invoiceNumber: masterInvoiceNumber,
                updates : toUpdate
            }
        })

        console.log('Refetching!')
        refetch()
    }
    const addDefault = (op) => {
        const defaultVal = {
            id: -1,
            name: 'Select...'
        }
        let optionsToReturn = op
        if (!op.find(o => o.id === -1)) {
            optionsToReturn.unshift(defaultVal)
        }

        return optionsToReturn
    }

    const filterOnChange = async (kv) => {
        let newVals = {}
        let options = {}
        if (kv.value.type === 'radio') {
            const value = parseInt(kv.value.value)
            newVals = { ...filterVals, [`${kv.name}`]: value }
            options = { ...selection, [`${kv.name}`]: value }

            const newState = { ...newVals }
            const newOptions = { ...options }

            await updateFilterVals(prv => newState)
            await updateSelection(prv => newOptions)

        } else if (kv.value && kv.value.id >= 0) {
            newVals = { ...filterVals, [`${kv.name}`]: kv.value.id }
            options = { ...selection, [`${kv.name}`]: { name: kv.value.name, id: kv.value.id } }

            const newState = { ...newVals }
            const newOptions = { ...options }

            await updateFilterVals(prv => newState)
            await updateSelection(prv => newOptions)
        } else {
            const { ...state } = filterVals
            const { ...ops } = selection

            delete state[kv.name]
            delete ops[kv.name]

            const newState = { ...state }
            const newOptions = { ...ops }

            await updateFilterVals(newState)
            await updateSelection(newOptions)
        }
    }

    const toggleInvoiced = (acct) => {
        let currentInvoiced = { ...invoiced };
        currentInvoiced[acct].selected = !currentInvoiced[acct].selected
        console.log('activated ', acct)
        updateInvoiced(currentInvoiced)
    }

    const updateChecked = (event, handleChange) => {
        const { id, name, value } = event.target
        toggleInvoiced(value)
        return handleChange(event)
    }

    const updateInvDate = (event, field) =>{
        const { id, name, value } = event.target
        const _invoiced = { ...invoiced }
        _invoiced[field].paidCADate = value
        updateInvoiced(_invoiced)
    }

    const checkAll = () => {
        let _invoiced = { ...invoiced }
        const values = Object.values(invoiced),
            keys = Object.keys(invoiced)
        if (values.every(i => i.selected))
            keys.map(k => _invoiced[k] = { ..._invoiced[k], selected: false })
        else if (values.some(i => !i.selected))
            keys.map(k => _invoiced[k].selected = true)
        updateInvoiced(_invoiced)
    }
    const selectedAccts = Object.values(invoiced).filter(i=> i.selected)
    const invoiceAble = selectedAccts.length > 0 && selectedAccts.every(a => a.paidCADate != null)
    // debugger;
    const FormFooterColumn = ({ children, style,...props }) => <div {...props} style={{ display: 'flex', flexDirection: 'column', padding: '10px 10px', marginBottom: '15px', justifyContent: 'center', ...style }}>{children}</div>

    return (
        <>
            <div className='PIfilterStyle'>
                <FormSimpleSelect
                    label="Hospital"
                    className='PIfieldStyle'
                    onChange={(e) => filterOnChange({
                        name: 'hospitalId',
                        value: e || null
                    })}
                    items={data.hospitals ? addDefault(data.hospitals) : []}
                    value={selection.hospitalId ? selection.hospitalId : { id: -1, name: 'Select...' }} />
            </div>
            <Divider />
            <Formik
                initialValues={data.claims ? {claims: data.claims, masterInvoiceNumber: null, masterInvoicedDate: null} : []}
                onSubmit={onSubmit}
                enableReinitialize={true}
                render={({ values, handleChange }) => (
                    <Form>
                        <FieldArray name="data.claims"
                            render={arrayHelpers => (
                                <div>
                                    <Table>
                                        <thead className='grayHeader'>
                                            <tr>
                                                {selection.hospitalId != undefined && <th className='grayHeader'>
                                                    <button type="button" onClick={ ()=> checkAll() }>
                                                        {Object.values(invoiced).every(i => i.selected) && "None"}
                                                        {Object.values(invoiced).some(i => !i.selected) && "All" }
                                                    </button>
                                                </th> }
                                                <th className='PIheaderStyle grayHeader'>Account #</th>
                                                {selection.hospitalId == undefined && <th className='PIheaderStyle grayHeader'>Hospital</th>}
                                                <th className='PIheaderStyle grayHeader'>Patient Name</th>
                                                <th className='PIheaderStyle grayHeader'>Admit Date</th>
                                                <th className='PIheaderStyle grayHeader'>Discharge Date</th>
                                                <th className='PIheaderStyle grayHeader'>Payer</th>
                                                <th className='PIshortHeaderStyle grayHeader'>Charges</th>
                                                {selection.hospitalId != undefined && <th className='PIlongHeaderStyle grayHeader'>Claim Paid Date</th> }
                                                <th className='PIheaderStyle grayHeader'>Total Recovery</th>
                                            </tr>
                                        </thead>

                                        {values && values.claims && values.claims.length > 0 ?
                                            <tbody>
                                                {values.claims.map((claim, index) => (
                                                    <tr className='' key={index} onClick={ (e)=> toggleInvoiced(claim.account) }>
                                                        { selection.hospitalId != undefined &&
                                                            <td className='PIcheckboxStyle centered'>
                                                                <Field
                                                                    name={`invoiced`}
                                                                    id={`invoiced.${index}`}
                                                                    type="checkbox"
                                                                    onClick={(e) => e.stopPropagation()}
                                                                    value={claim.account}
                                                                    checked={invoiced[claim.account] && invoiced[claim.account].selected}
                                                                    disabled={selection.invoiced === 1 || selection.hospitalId == undefined}
                                                                    onChange={e => updateChecked(e, handleChange)}
                                                                />
                                                            </td>
                                                        }
                                                        <td className='PIcellStyle'>{claim.account}</td>
                                                        { selection.hospitalId == undefined &&
                                                            <td className='PIcellStyle'>
                                                            <a href="#!" onClick={(e) => { e.stopPropagation(); filterOnChange({ name: 'hospitalId', value: claim.hospital || null}) }} >
                                                                {claim.hospital.name}
                                                            </a>
                                                        </td>
                                                        }
                                                        <td className='PIcellStyle'>{`${claim.lastName}, ${claim.firstName}`}</td>
                                                        <td className='PIcellStyle'>{claim.admitDOSDate}</td>
                                                        <td className='PIshortCellStyle'>{claim.dischargeDate}</td>
                                                        <td className='PIshortCellStyle'>{claim.payer}</td>
                                                        <td className='PIdollarStyle'>{formatDollarAmt(claim.billedCharges)}</td>
                                                        {selection.hospitalId != undefined && <td className='PIDateStyle'>
                                                            <input
                                                                // name={claim.invoiced && values.masterInvoicedDate ? "masterInvoicedDate" : `invoiced[${index}]`}
                                                                name={`invoicedDate[${claim.account}]`}
                                                                id={`invoicedDate[${index}]`}
                                                                type="date"
                                                                disabled={invoiced[claim.account] && !invoiced[claim.account].selected}
                                                                value={invoiced[claim.account] && invoiced[claim.account].paidCADate || ''}
                                                                style={{ 'width': '130px' }}
                                                                onClick={(e) => e.stopPropagation()}
                                                                onChange={(e) => updateInvDate(e,  claim.account)}
                                                            />
                                                        </td> }
                                                        <td>{formatDollarAmt(claim.revenue)}</td>
                                                    </tr>))}</tbody> : null}
                                    </Table>
                                    {selection.hospitalId != undefined &&
                                        <div className='PIfilterStyle'>
                                            <FormikInput
                                                className='PIinvoiceStyle'
                                                name="masterInvoiceNumber"
                                                id="masterInvoiceNumber"
                                                label="Invoice Number"
                                            /><FormikInput
                                                className='PIinvoiceStyle'
                                                name="masterInvoicedDate"
                                                id="masterInvoicedDate"
                                                label="Invoice Date"
                                                type="date"
                                                placeholder="MM/DD/YYYY"
                                        />
                                        <FormFooterColumn>
                                            <label> {selectedAccts.length} Records Selected </label>
                                                <ButtonLink type="submit" name="save" intent="primary" disabled={!invoiceAble}>
                                                        Add to Invoice
                                                </ButtonLink>
                                        </FormFooterColumn>
                                        <FormFooterColumn style={{color: 'darkred'}}>
                                                {
                                                    selectedAccts.length > 0 && !invoiceAble && <span className="error"> Selected items must have an "Claim Paid Date" set. </span>
                                                }
                                         </FormFooterColumn>
                                     </div>
                                    }
                                </div>)} />


                    </Form>)} />

        </>
    )
}
