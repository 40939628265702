import React from 'react'
import {useDropzone} from 'react-dropzone'

const dropZoneStyle = {
    border: "1px solid black",
    minHeight: "100px",
    margin: "15px",
    padding: "10px"
}

export default (fileToUpload, updateFileToUpload) => {
  console.log(updateFileToUpload)
  const dropBoxDisplay = fileToUpload && fileToUpload[0] ? <p>{fileToUpload[0].name}</p> : <p>Drag and drop a file here ...</p>

    const onDrop = (acceptedFiles)  => {
      fileToUpload = acceptedFiles
      console.log('fieles', fileToUpload[0])
      console.log('also files', acceptedFiles)
    }
  const {getRootProps, getInputProps} = useDropzone({onDrop})

  return (
    <div style={dropZoneStyle} {...getRootProps()}>
      <input {...getInputProps()} />
      {dropBoxDisplay}
    </div>
  )
}